import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import Card from "../../components/Card/Card";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Alerte = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }
  });

  return (
    <Container>
      <Row>
        <div className="shadow" style={{ width: "100%" }}>
          <Table className="table table-responsive-xl bg-white br-1">
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Nom</th>
                <th>Nb Appel</th>
                <th>Nb Heure</th>
                <th>Depenses</th>
              </tr>
            </thead>
            <tbody>
              <tr class="alert" role="alert">
                <td>
                  <label class="checkbox-wrap checkbox-primary">
                    <input type="checkbox" checked />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="d-flex align-items-center">
                  <div
                    class="img"
                    style={{
                      backgroundImage: "url(images/person_1.jpg);",
                    }}
                  ></div>
                  <div class="pl-3 email">
                    <span>markotto@email.com</span>
                    <span>Added: 01/03/2020</span>
                  </div>
                </td>
                <td>Markotto89</td>
                <td class="status">
                  <span class="active">Active</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-close"></i>
                    </span>
                  </button>
                </td>
              </tr>
              <tr class="alert" role="alert">
                <td>
                  <label class="checkbox-wrap checkbox-primary">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="d-flex align-items-center">
                  <div
                    class="img"
                    style={{
                      backgroundImage: "url(images/person_2.jpg);",
                    }}
                  ></div>
                  <div class="pl-3 email">
                    <span>jacobthornton@email.com</span>
                    <span>Added: 01/03/2020</span>
                  </div>
                </td>
                <td>Jacobthornton</td>
                <td class="status">
                  <span class="waiting">Waiting for Resassignment</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-close"></i>
                    </span>
                  </button>
                </td>
              </tr>
              <tr class="alert" role="alert">
                <td>
                  <label class="checkbox-wrap checkbox-primary">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="d-flex align-items-center">
                  <div
                    class="img"
                    style={{
                      backgroundImage: "url(images/person_3.jpg);",
                    }}
                  ></div>
                  <div class="pl-3 email">
                    <span>larrybird@email.com</span>
                    <span>Added: 01/03/2020</span>
                  </div>
                </td>
                <td>Larry_bird</td>
                <td class="status">
                  <span class="active">Active</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-close"></i>
                    </span>
                  </button>
                </td>
              </tr>
              <tr class="alert" role="alert">
                <td>
                  <label class="checkbox-wrap checkbox-primary">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="d-flex align-items-center">
                  <div
                    class="img"
                    style={{
                      backgroundImage: "url(images/person_4.jpg);",
                    }}
                  ></div>
                  <div class="pl-3 email">
                    <span>johndoe@email.com</span>
                    <span>Added: 01/03/2020</span>
                  </div>
                </td>
                <td>Johndoe1990</td>
                <td class="status">
                  <span class="active">Active</span>
                </td>
                <td>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-close"></i>
                    </span>
                  </button>
                </td>
              </tr>
              <tr class="alert" role="alert">
                <td class="border-bottom-0">
                  <label class="checkbox-wrap checkbox-primary">
                    <input type="checkbox" />
                    <span class="checkmark"></span>
                  </label>
                </td>
                <td class="d-flex align-items-center border-bottom-0">
                  <div
                    class="img"
                    style={{
                      backgroundImage: "url(images/person_1.jpg);",
                    }}
                  ></div>
                  <div class="pl-3 email">
                    <span>garybird@email.com</span>
                    <span>Added: 01/03/2020</span>
                  </div>
                </td>
                <td class="border-bottom-0">Garybird_2020</td>
                <td class="status border-bottom-0">
                  <span class="waiting">Waiting for Resassignment</span>
                </td>
                <td class="border-bottom-0">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i class="fa fa-close"></i>
                    </span>
                  </button>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Row>
    </Container>
  );
};

export default Alerte;
