import {
  EXTENSION_LIST_REQUEST,
  EXTENSION_LIST_SUCCESS,
  EXTENSION_LIST_FAIL,
  EXTENSION_ASSIGN_ACCESS_REQUEST,
  EXTENSION_ASSIGN_ACCESS_SUCCESS,
  EXTENSION_ASSIGN_ACCESS_FAIL,
  EXTENSION_UPDATE_REQUEST,
  EXTENSION_UPDATE_SUCCESS,
  EXTENSION_UPDATE_FAIL,
  EXTENSION_ASSIGN_CAMPAGNE_REQUEST,
  EXTENSION_ASSIGN_CAMPAGNE_SUCCESS,
  EXTENSION_ASSIGN_CAMPAGNE_FAIL,
  GET_LIST_AGENT_AND_CAMPAGNE_REQUEST,
  GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS,
  GET_LIST_AGENT_AND_CAMPAGNE_FAIL,
} from "../constants/extensionConstants";
import axios from "axios";

export const getNumeroList = (query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXTENSION_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/myNumero?${query}`,
      config
    );
    dispatch({
      type: EXTENSION_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: EXTENSION_LIST_FAIL,
      payload: message,
    });
  }
};
export const assignAccess =
  (extensions, agents) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXTENSION_ASSIGN_ACCESS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const data = {
        numeros: extensions,
        agents: agents,
      };
      const requete = await axios.patch(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/addAgents`,
        data,
        {
          headers: config.headers,
        }
      );
      dispatch({
        type: EXTENSION_ASSIGN_ACCESS_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: EXTENSION_ASSIGN_ACCESS_FAIL,
        payload: message,
      });
    }
  };
export const updateNumeroAcces = (id, agents) => async (dispatch, getState) => {
  try {
    dispatch({
      type: EXTENSION_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/${id}`,
      agents,
      config
    );

    dispatch({ type: EXTENSION_UPDATE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: EXTENSION_UPDATE_FAIL,
      payload: message,
    });
  }
};
export const assignCampagne =
  (numeros, campagnes) => async (dispatch, getState) => {
    try {
      dispatch({
        type: EXTENSION_ASSIGN_CAMPAGNE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const data = {
        numeros: numeros,
        campagne: campagnes,
      };
      const requete = await axios.patch(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/addNumero`,
        data,
        {
          headers: config.headers,
        }
      );
      dispatch({
        type: EXTENSION_ASSIGN_CAMPAGNE_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: EXTENSION_ASSIGN_CAMPAGNE_FAIL,
        payload: message,
      });
    }
  };

export const getListCampagneAndAgent = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LIST_AGENT_AND_CAMPAGNE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const data = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/campagnesAgents`,
      {
        headers: config.headers,
      }
    );
    dispatch({
      type: GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS,
      payload: data?.data?.data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: GET_LIST_AGENT_AND_CAMPAGNE_FAIL,
      payload: message,
    });
  }
};

export const updateOneNumero = async (id, datas, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/${id}`,
      datas,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateAudioOneNumero = async (id, audio, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/importAudioForVoiceMailPrompt/${id}`,
      audio,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getNumeroDisponible = async (token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/disponible`,
      config
    );
    return data;
  } catch (err) {
    return err;
  }
};
