import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserStats, register } from "../../redux/actions/userActions";
import { getHistoriqueListForOneUser } from "../../redux/actions/appelActions";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IoMdEye } from "react-icons/io";
import { IoMdEyeOff } from "react-icons/io";
import "./style.css"
const CreateAgent = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const [typePassword, setTypePassword] = useState('password');
    const [typeConfirmPassword, setTypeConfirmPassword] = useState('password');
    const [passwordIcon, setPasswordIcon] = useState(<IoMdEye className="absolute mr-10" size={25} />);
    const [confirmPasswordIcon, setConfirmPasswordIcon] = useState(<IoMdEye className="absolute mr-10" size={25} />);
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const handleTogglePassword = () => {
        if (typePassword === 'password') {
            setPasswordIcon(<IoMdEye className="absolute mr-10" size={25} />);
            setTypePassword('text');
        } else {
            setPasswordIcon(<IoMdEyeOff className="absolute mr-10" size={25} />);
            setTypePassword('password');
        }
    }
    const handleToggleConfirmPassword = () => {
        if (typeConfirmPassword === 'password') {
            setConfirmPasswordIcon(<IoMdEye className="absolute mr-10" size={25} />);
            setTypeConfirmPassword('text');
        } else {
            setConfirmPasswordIcon(<IoMdEyeOff className="absolute mr-10" size={25} />);
            setTypeConfirmPassword('password');
        }
    }
    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else {
            dispatch(getUserStats(id));
            dispatch(
                getHistoriqueListForOneUser(
                    id,
                    "fields=_id,clientId,calldate,billsec,dst,disposition"
                )
            );
        }
    }, [dispatch, navigate, userInfo, id]);
    const handleSave = () => {
        const dataUser = {
            nom: name,
            prenom: lastName,
            email: email,
            password: password,
            passwordConfirm: confirmPassword
        };
        dispatch(register(dataUser));
        props.onHide();
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            className="modal-create-budget"
            centered
        >
            <Modal.Header className="modal-create-budget-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    CRÉER UN AGENT
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-create-budget-body">
                <Form>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col sm="12">
                                <Form.Label>Nom:</Form.Label>
                            </Col>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control " 
                                    type="text"
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Veuillez saisir votre nom"
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Row>
                            <Col sm="12">
                                <Form.Label>Prénom:</Form.Label>
                            </Col>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="text"
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder="Veuillez saisir votre prénom" />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3" >
                        <Row>
                            <Col sm="12">
                                <Form.Label>Email:</Form.Label>
                            </Col>
                            <Col sm="12">
                                <Form.Control
                                    className="custom-form-control"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Veuillez saisir votre adresse émail" />
                                
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Row>
                            <Col sm="12">
                                <Form.Label> Mot de passe:</Form.Label>
                            </Col>
                            <Col sm="12">
                                <div className="input-group">
                                    <Form.Control
                                        type={typePassword}
                                        name="password"
                                        placeholder="Choisissez un mot de passe"
                                        onChange={(e) => { setPassword(e.target.value) }}
                                        className="custom-form-control"
                                    />
                                    <span onClick={handleTogglePassword} style={{ cursor: 'pointer', backgroundColor: '#f7f7f9' }}>
                                        {passwordIcon}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Row>
                            <Col sm="12">
                                <Form.Label>Confirmer le mot de passe:</Form.Label>
                            </Col>
                            <Col sm="12">
                                <div className="input-group">
                                    <Form.Control
                                        type={typeConfirmPassword}
                                        name="confirmPassword"
                                        placeholder="Confirmer le mot de passe"
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                        className="custom-form-control"
                                    />
                                    <span onClick={handleToggleConfirmPassword} style={{ cursor: 'pointer', backgroundColor: '#f7f7f9' }}>
                                        {confirmPasswordIcon}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="modal-create-budget-footer d-flex justify-content-center">
                <Button onClick={props.onHide} className="btn-create-budget">Annuler</Button>
                <Button onClick={() => handleSave()} className="btn-create-budget">Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateAgent;
