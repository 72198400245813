import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import {
  getListNoire,
  addListNoire,
  deleteListNoire,
} from "../../redux/actions/trunkActions";
import Loader from "../../components/Loader";
const ModalListeNoireEntrante = (props) => {
  const [data, setData] = useState([]);
  const [newData, setNewData] = useState([]);
  const [dataToDelete, setDataToDelete] = useState([]);
  const [wait, setWait] = useState(false);
  //recuperation des numéros en listes noires
  useEffect(() => {
    if (props._id) {
      setWait(true);
      getListNoire(props._id,"appelType=1", props.token)
        .then((res) => {
          setDataToDelete([]);
          setNewData([]);
          if (res.data) {
            setData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((res) => setWait(false));
    } else {
      setData([]);
    }
  }, [props._id]);
  return (
    <>
      <Modal {...props}>
        <Modal.Header
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h5>Numéros bloqués - appels entrants</h5>
          <span onClick={props.onHide}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body>
          {wait ? (
            <Loader />
          ) : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setNewData([...newData, { value: "" }]);
                  }}
                >
                  +Ajouter
                </button>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {newData.map((el, i) => {
                  return (
                    <>
                      <div style={{ width: "100%", padding: "10px 10px" }}>
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={el.value}
                          onChange={(ev) => {
                            setNewData(
                              newData.map((obj, id) => {
                                if (id == i) {
                                  return {
                                    value: ev.target.value,
                                  };
                                } else {
                                  return obj;
                                }
                              })
                            );
                          }}
                          placeholder="numéro"
                        />
                      </div>
                    </>
                  );
                })}
              </div>
              <div>Listes des numéros dans la liste noire des appels entrants.</div>
              <div>
                {data.map((el, i) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: "10px 5px",
                        }}
                        id={el._id}
                      >
                        <label>{el.value ? el.value : "-"}</label>
                        <i
                          class="bi bi-trash-fill"
                          style={{ color: "red" }}
                          onClick={(ev) => {
                            ev.preventDefault();
                            const cible = document.getElementById(el._id);
                            cible.style = "display:none";
                            setDataToDelete([...dataToDelete, el]);
                          }}
                        ></i>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            Fermer
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#61CE70 ",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={(ev) => {
              ev.preventDefault();
              const newDataToAdd = newData.filter((el) => el.value);
              if (newDataToAdd && newDataToAdd.length > 0) {
                const dataToSend = {
                  phoneNumberToCreate: newDataToAdd,
                  trunk: props._id,
                  client: props.user?._id,
                  appelType:1
                };
                addListNoire(props.token, dataToSend)
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              }
              if (dataToDelete && dataToDelete.length > 0) {
                deleteListNoire(props.token, {
                  phoneNumberToDelete: dataToDelete,
                })
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              }
              props.onHide();
            }}
          >
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalListeNoireEntrante;
