import {
  APPEL_LIST_REQUEST,
  APPEL_LIST_SUCCESS,
  APPEL_LIST_FAIL,
} from "../constants/appelConstatns";

export const agentHistoriquesReducer = (state = { historiques: [] }, action) => {
  switch (action.type) {
    case APPEL_LIST_REQUEST:
      return { agent_historique_loading: true };
    case APPEL_LIST_SUCCESS:
      return {
        agent_historique_loading: false,
        historiques: action.payload
      };
    case APPEL_LIST_FAIL:
      return {
        agent_historique_loading: false,
        agent_historique_error: action.payload
      };
    default:
      return state;
  }
};
