import axios from "axios";
import {
  COMPAGNE_STATS_REQUEST,
  COMPAGNE_STATS_SUCCESS,
  COMPAGNE_STATS_FAIL,
  COMPAGNE_LIST_REQUEST,
  COMPAGNE_LIST_SUCCESS,
  COMPAGNE_LIST_FAIL,
  COMPAGNE_CREATE_REQUEST,
  COMPAGNE_CREATE_SUCCESS,
  COMPAGNE_CREATE_FAIL,
  COMPAGNE_ASSIGN_ACCESS_REQUEST,
  COMPAGNE_ASSIGN_ACCESS_SUCCESS,
  COMPAGNE_ASSIGN_ACCESS_FAIL,
  COMPAGNE_UPDATE_REQUEST,
  COMPAGNE_UPDATE_SUCCESS,
  COMPAGNE_UPDATE_FAIL,
  COMPAGNE_DELETE_REQUEST,
  COMPAGNE_DELETE_SUCCESS,
  COMPAGNE_DELETE_FAIL,
  CAMPAGNE_ASSIGN_NUMERO_REQUEST,
  CAMPAGNE_ASSIGN_NUMERO_SUCCESS,
  CAMPAGNE_ASSIGN_NUMERO_FAIL,
  CAMPAGNE_DETAILS_FAIL,
  CAMPAGNE_DETAILS_SUCCESS,
  CAMPAGNE_DETAILS_REQUEST,
  CAMPAGNE_UPDATE_NUMERO_REQUEST,
  CAMPAGNE_UPDATE_NUMERO_SUCCESS,
  CAMPAGNE_UPDATE_NUMERO_FAIL,
} from "../constants/compagneConstants";

export const getInfoAllAgentAndCampagne = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPAGNE_STATS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/infos`,
      config
    );
    dispatch({
      type: COMPAGNE_STATS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPAGNE_STATS_FAIL,
      payload: message,
    });
  }
};
export const getAllCompagne = (query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPAGNE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/?${
        query || ""
      }`,
      config
    );

    dispatch({
      type: COMPAGNE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPAGNE_LIST_FAIL,
      payload: message,
    });
  }
};
export const createCampagne = (campagne) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPAGNE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/`,
      campagne,
      config
    );
    dispatch({
      type: COMPAGNE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPAGNE_CREATE_FAIL,
      payload: message,
    });
  }
};
export const assignAccess =
  (campagnes, agents) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPAGNE_ASSIGN_ACCESS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const data = {
        campagnes: campagnes,
        agents: agents,
      };
      const requete = await axios.patch(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/addAgents`,
        data,
        {
          headers: config.headers,
        }
      );
      dispatch({
        type: COMPAGNE_ASSIGN_ACCESS_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: COMPAGNE_ASSIGN_ACCESS_FAIL,
        payload: message,
      });
    }
  };
export const assignNumero =
  (campagnes, numeros) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CAMPAGNE_ASSIGN_NUMERO_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const data = {
        numeros: numeros,
        campagnes: campagnes,
      };
      const requete = await axios.patch(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/addNumero`,
        data,
        {
          headers: config.headers,
        }
      );
      dispatch({
        type: CAMPAGNE_ASSIGN_NUMERO_SUCCESS,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: CAMPAGNE_ASSIGN_NUMERO_FAIL,
        payload: message,
      });
    }
  };
export const updateCampagneAcces =
  (id, campagne) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COMPAGNE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      console.log("campagne", campagne);
      const { data } = await axios.patch(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/${id}`,
        campagne,
        config
      );

      dispatch({ type: COMPAGNE_UPDATE_SUCCESS });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: COMPAGNE_UPDATE_FAIL,
        payload: message,
      });
    }
  };
export const updateNumeroCampagne = (numeros) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAMPAGNE_UPDATE_NUMERO_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const data = {
      numeros: numeros,
    };
    console.log("data", data);
    console.log("data actions", data);
    await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/numeros/`,
      data,
      config
    );

    dispatch({ type: CAMPAGNE_UPDATE_NUMERO_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CAMPAGNE_UPDATE_NUMERO_FAIL,
      payload: message,
    });
  }
};
export const deleteCampagne = (idDelete) => async (dispatch, getState) => {
  try {
    dispatch({
      type: COMPAGNE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const data = {
      docToDelete: idDelete,
    };

    await axios.delete(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/deleteMany`,
      {
        headers: config.headers,
        data: data,
      }
    );

    dispatch({ type: COMPAGNE_DELETE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COMPAGNE_DELETE_FAIL,
      payload: message,
    });
  }
};
export const getCampagneDetails = (ids) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CAMPAGNE_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const dataUserDetails = [];
    for (const id of ids) {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/${id}`,
        config
      );
      dataUserDetails.push(data.data);
    }

    dispatch({
      type: CAMPAGNE_DETAILS_SUCCESS,
      payload: dataUserDetails,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: CAMPAGNE_DETAILS_FAIL,
      payload: message,
    });
  }
};
export const updateOneCampagne = async (id, campagne, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/campagnes/${id}`,
      campagne,
      config
    );

    return data;
  } catch (error) {
    return error;
  }
};
