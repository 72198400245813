import {
    CREDIT_LIST_REQUEST,
    CREDIT_LIST_SUCCESS,
    CREDIT_LIST_FAIL,
} from "../constants/creditConstants";

export const creditReducer = (state = { credits: [] }, action) => {
    switch (action.type) {
        case CREDIT_LIST_REQUEST:
            return { credit_loading: true };
        case CREDIT_LIST_SUCCESS:
            return {
                credit_loading: false,
                credits: action.payload,
            };
        case CREDIT_LIST_FAIL:
            return {
                credit_loading: false,
                credit_error: action.payload,
            };
        default:
            return state;
    }
};
