export const EXTENSION_LIST_REQUEST = "EXTENSION_LIST_REQUEST";
export const EXTENSION_LIST_SUCCESS = "EXTENSION_LIST_SUCCESS";
export const EXTENSION_LIST_FAIL = "EXTENSION_LIST_FAIL";

export const EXTENSION_ASSIGN_ACCESS_REQUEST = "EXTENSION_ASSIGN_ACCESS_REQUEST";
export const EXTENSION_ASSIGN_ACCESS_SUCCESS = "EXTENSION_ASSIGN_ACCESS_SUCCESS";
export const EXTENSION_ASSIGN_ACCESS_FAIL = "EXTENSION_ASSIGN_ACCESS_FAIL";

export const EXTENSION_ASSIGN_CAMPAGNE_REQUEST = "EXTENSION_ASSIGN_CAMPAGNE_REQUEST";
export const EXTENSION_ASSIGN_CAMPAGNE_SUCCESS = "EXTENSION_ASSIGN_CAMPAGNE_SUCCESS";
export const EXTENSION_ASSIGN_CAMPAGNE_FAIL = "EXTENSION_ASSIGN_CAMPAGNE_FAIL";

export const EXTENSION_UPDATE_REQUEST = "EXTENSION_UPDATE_REQUEST";
export const EXTENSION_UPDATE_SUCCESS = "EXTENSION_UPDATE_SUCCESS";
export const EXTENSION_UPDATE_FAIL = "EXTENSION_UPDATE_FAIL";

export const GET_LIST_AGENT_AND_CAMPAGNE_REQUEST = "GET_LIST_AGENT_AND_CAMPAGNE_REQUEST";
export const GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS  = "GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS";
export const GET_LIST_AGENT_AND_CAMPAGNE_FAIL = "GET_LIST_AGENT_AND_CAMPAGNE_FAIL";