import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { register } from "../../redux/actions/userActions";
const ModalCreateAgent = () => {
const dispatch = useDispatch()
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [confirmPass, SetConfirmPass] = useState("");
  const handleSave = () => {
    if(pass==confirmPass){
        const dataUser = {
            nom:nom,
            prenom:prenom,
            email:email,
            password:pass,
            passwordConfirm:confirmPass
        }
      dispatch(register(dataUser))
      const cible = document.getElementById('myModalCreateAgent')
      if(cible){
        cible.style='display:none'
      }
    }else{
        alert('les deux mot de passe ne sont pas identiquent.')
    }
  }
  return (
    <>
      <div class="modal" id="myModalCreateAgent" style={{zIndex:'9999'}}>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Ajouter un agent</h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <form onSubmit={(ev) => {
                ev.preventDefault()
                handleSave()
            }}>
              <div class="modal-body">
                <div class="mb-3 mt-3">
                  <label class="form-label">Nom:</label>
                  <input
                    onChange={(ev) => setNom(ev.target.value)}
                    type="text"
                    class="form-control"
                    placeholder="Nom"
                    required
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Prénom:</label>
                  <input
                    onChange={(ev) => setPrenom(ev.target.value)}
                    type="text"
                    class="form-control"
                    placeholder="Prénom"
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Email:</label>
                  <input
                    onChange={(ev) => setEmail(ev.target.value)}
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    required
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Mot de passe:</label>
                  <input
                    onChange={(ev) => setPass(ev.target.value)}
                    type="text"
                    class="form-control"
                    placeholder="********"
                    required
                  />
                </div>
                <div class="mb-3 mt-3">
                  <label class="form-label">Confirmer mot de passe:</label>
                  <input
                    onChange={(ev) => SetConfirmPass(ev.target.value)}
                    type="text"
                    class="form-control"
                    placeholder="********"
                    required
                  />
                </div>
              </div>

              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
                <button type="submit" class="btn btn-success" data-bs-dismiss="modal">
                  Enregistrer
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalCreateAgent;
