import React, { useState, useEffect } from "react";

import axios from "axios";
import imageRecepteur from "../../assets/images/user.png";
const FluxAppel = ({ dataCell, userInfo }) => {
  const [audioURL, setAudioURL] = useState();
  const [note, setNote] = useState();

  //verisifcation status de l'appel (entrante, sortante,manqué)
  const verificationStatusAppel = (el) => {
    //appel sortant 2 , manque 3, entrante 1 , repondeur: 4
    if (el.statutAppel == 2) {
      return (
        <i
          class="bi bi-telephone-outbound-fill"
          style={{ color: "#00e7eb" }}
        ></i>
      );
    } else {
      if (el.statutAppel == 3) {
        return (
          <i class="bi bi-telephone-x-fill" style={{ color: "#EB455F" }}></i>
        );
      } else {
        if (el.statutAppel == 1) {
          return (
            <i
              class="bi bi-telephone-inbound-fill"
              style={{ color: "#6cc070" }}
            ></i>
          );
        } else {
          if (el.statutAppel == 4) {
            return <i class="bi bi-voicemail" style={{ color: "orange" }}></i>;
          } else {
            return (
              <i class="bi bi-telephone-x-fill" style={{ color: "orange" }}></i>
            );
          }
        }
      }
    }
  };
  //verification si le numero est dans contacte
  const verifyIfContact = (numero, contacts) => {
    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].tel === numero) {
        return contacts[i].name;
      }
    }
    return "inconnu";
  };
  const verificationExterne = (el) => {
    if (el.pointeurNum == "src") {
      return el.dst;
    } else {
      return el.src;
    }
  };

  //formatage de la duration de l'appel
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  //verification flux de l'appel, qui à fait quoi
  const verificationFlux = (el) => {
    if (el.statutAppel == 1 && el.disposition === "ANSWERED") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <img className="imgRecepteur" src={imageRecepteur} alt="" />
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Décroché
            </div>{" "}
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 1 && el.disposition === "NO ANSWER") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 3) {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "ANSWERED") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <img className="imgRecepteur" src={imageRecepteur} alt="" />
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Décroché par l'appelé
            </div>{" "}
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "NO ANSWER") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 2 && el.disposition === "BUSY") {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-up-right"
              style={{ color: "#00e7eb" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel sortant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-telephone-fill" style={{ color: "#EB455F" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Fin de l'appel <br /> {el.billsec}{" "}
            </div>{" "}
          </span>
        </>
      );
    } else if (el.statutAppel == 4) {
      return (
        <>
          <span className="flux">
            {" "}
            <i
              class="bi bi-arrow-down-left"
              style={{ color: "#6cc070" }}
            ></i>{" "}
            <span className="flux-appels" style={{ marginLeft: "10px" }}>
              Appel entrant
            </span>
          </span>
          <span className="flux">
            {" "}
            <i class="bi bi-voicemail" style={{ color: "orange" }}></i>
            <div className="flux-appels" style={{ marginLeft: "10px" }}>
              Répondeur
            </div>{" "}
          </span>
        </>
      );
    } else {
      {
        return <span className="flux-appel">Non disponible</span>;
      }
    }
  };

  //verificaiton de la badge 1.2.3.4.5.6
  const verificationBadge = (el) => {
    if (el == 1) {
      return <i class="bi bi-star" style={{ color: "gray" }}></i>;
    } else if (el == 2) {
      return <i class="bi bi-star-fill" style={{ color: "#FFC436" }}></i>;
    } else if (el == 3) {
      return <i class="bi bi-star-fill" style={{ color: "#00e7eb" }}></i>;
    } else if (el == 4) {
      return <i class="bi bi-star-fill" style={{ color: "orange" }}></i>;
    } else if (el == 5) {
      return (
        <i
          class="bi bi-exclamation-circle-fill"
          style={{ color: "#EB455F" }}
        ></i>
      );
    } else if (el == 6) {
      return (
        <i class="bi bi-check-circle-fill" style={{ color: "#6cc070" }}></i>
      );
    } else {
      return <i class="bi bi-star" style={{ color: "gray" }}></i>;
    }
  };
  const formatDate = (dateString) => {
    const dateObj = new Date(dateString);

    const jour = dateObj.getUTCDate();
    const mois = dateObj.toLocaleString("default", { month: "short" });
    const heure = ("0" + dateObj.getUTCHours()).slice(-2);
    const minutes = ("0" + dateObj.getUTCMinutes()).slice(-2);

    const dateFormatee = `${jour} ${mois} ${heure}:${minutes}`;
    return dateFormatee;
  };
  //Recuperation audio et repondeur
  const recuperationAudio = async (filename) => {
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      responseType: "blob",
    };
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/audio/${filename}`,
        config
      )
      .then((res) => {
        if (res.data) {
          const audioBlob = res.data;
          const url = URL.createObjectURL(audioBlob);
          const file = new File([audioBlob], filename, {
            type: audioBlob.type,
          });
          // handleTranscription(file)
          setAudioURL(url);
        } else {
          setAudioURL(null);
        }
      })
      .catch((err) => {
        setAudioURL(null);
        console.log(err);
      });
  };
  useEffect(() => {
    if (dataCell) {
      setNote(dataCell.note);
      recuperationAudio(dataCell.recordingfile);
    }
  }, [dataCell]);
  return (
    <>
      <div class="offcanvas offcanvas-end" id="flux">
        <div class="offcanvas-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5 class="offcanvas-title" style={{ color: "#334155" }}>
            Fiche d'appel{" "}
          </h5>
          <div>
            <span
              style={{ marginRight: "20px", cursor: "pointer" }}
              data-bs-toggle="dropdown"
            >
              {verificationBadge(dataCell?.badge)}
            </span>

            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="offcanvas"
            ></button>
          </div>
        </div>
        <div
          class="offcanvas-body"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {dataCell && (
            <>
              {verificationStatusAppel(dataCell)}
              <span style={{ marginTop: "5px" }}>
                {" "}
                {formatDate(dataCell.calldate)} .{" "}
                {dataCell.billsec}{" "}
              </span>
              <div className="recepteur" style={{ marginTop: "5px" }}>
                <img className="imgRecepteur" src={imageRecepteur} alt="" />
                <div className="infoRecepteur">
                  <span className="nomRecepteur">
                    {" "}
                    {verificationExterne(dataCell)}
                  </span>
                  <span className="telRecepteur">
                    {verifyIfContact(verificationExterne(dataCell), [])}
                  </span>
                </div>
              </div>
              {note && note.length > 0 ? (
                <>
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#e6e4e4",
                      borderRadius: "8px",
                      marginTop: "5px",
                      padding: "5px 5px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ marginLeft: "5px" }}>{note}</span>
                  </span>
                </>
              ) : (
                ""
              )}
              <span className="flux-appel"> Flux d'appel</span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {verificationFlux(dataCell)}
              </div>
              <span className="flux-appel">Enregistrement</span>
              <div style={{ marginTop: "10px",width:'100%',textAlign:'center'}}>
                {audioURL ? <audio controls src={audioURL} style={{width:'100%'}}/> : "---"}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FluxAppel;
