import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { deleteBudget } from "../../redux/actions/budgetActions";
const ModalConfirm = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        }
    }, [dispatch, navigate, userInfo, id]);
    const handleDeleteBudget = () => {
        dispatch(deleteBudget(props.idBudget));
        props.onHide();
    }
    return (
        <>
            <Modal
                size="sm"
                {...props}
                aria-labelledby="example-modal-sizes-title-sm"
                className="modal-create-budget"
                centered
            >
                <Modal.Header className="modal-create-budget-header" >
                    <Modal.Title id="example-modal-sizes-title-sm" >
                        Confirmez-vous la suppression?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-create-budget-body">
                    <p class="d-flex justify-content-center mb-3">Si vous confirmez,cette budget sera supprimé definitivement.</p>
                    <div class="d-flex justify-content-center ">
                        <Button onClick={props.onHide} className="btn-delete-budget btn-delete-budget-cancel mr-2">Annuler</Button>
                        <Button 
                        className="btn-delete-budget btn-delete-budget-confirm ml-2"
                        onClick={()=>handleDeleteBudget()}>Confirmer</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ModalConfirm;
