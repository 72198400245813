export const COMPAGNE_STATS_REQUEST = "COMPAGNE_STATS_REQUEST";
export const COMPAGNE_STATS_SUCCESS = "COMPAGNE_STATS_SUCCESS";
export const COMPAGNE_STATS_FAIL = "COMPAGNE_STATS_FAIL";

export const COMPAGNE_LIST_REQUEST = "COMPAGNE_LIST_REQUEST";
export const COMPAGNE_LIST_SUCCESS = "COMPAGNE_LIST_SUCCESS";
export const COMPAGNE_LIST_FAIL = "COMPAGNE_LIST_FAIL";

export const COMPAGNE_CREATE_REQUEST = "COMPAGNE_CREATE_REQUEST";
export const COMPAGNE_CREATE_SUCCESS = "COMPAGNE_CREATE_SUCCESS";
export const COMPAGNE_CREATE_FAIL = "COMPAGNE_CREATE_FAIL";

export const COMPAGNE_ASSIGN_ACCESS_REQUEST = "COMPAGNE_ASSIGN_ACCESS_REQUEST";
export const COMPAGNE_ASSIGN_ACCESS_SUCCESS = "COMPAGNE_ASSIGN_ACCESS_SUCCESS";
export const COMPAGNE_ASSIGN_ACCESS_FAIL = "COMPAGNE_ASSIGN_ACCESS_FAIL";

export const CAMPAGNE_ASSIGN_NUMERO_REQUEST = "CAMPAGNE_ASSIGN_NUMERO_REQUEST";
export const CAMPAGNE_ASSIGN_NUMERO_SUCCESS = "CAMPAGNE_ASSIGN_NUMERO_SUCCESS";
export const CAMPAGNE_ASSIGN_NUMERO_FAIL = "CAMPAGNE_ASSIGN_NUMERO_FAIL";

export const COMPAGNE_UPDATE_REQUEST = "COMPAGNE_UPDATE_REQUEST";
export const COMPAGNE_UPDATE_SUCCESS = "COMPAGNE_UPDATE_SUCCESS";
export const COMPAGNE_UPDATE_FAIL = "COMPAGNE_UPDATE_FAIL";

export const COMPAGNE_DELETE_REQUEST = "COMPAGNE_DELETE_REQUEST";
export const COMPAGNE_DELETE_SUCCESS = "COMPAGNE_DELETE_SUCCESS";
export const COMPAGNE_DELETE_FAIL = "COMPAGNE_DELETE_FAIL";

export const CAMPAGNE_DETAILS_REQUEST = "CAMPAGNE_DETAILS_REQUEST";
export const CAMPAGNE_DETAILS_SUCCESS = "CAMPAGNE_DETAILS_SUCCESS";
export const CAMPAGNE_DETAILS_FAIL = "CAMPAGNE_DETAILS_FAIL";

export const CAMPAGNE_UPDATE_NUMERO_REQUEST = "CAMPAGNE_UPDATE_NUMERO_REQUEST";
export const CAMPAGNE_UPDATE_NUMERO_SUCCESS = "CAMPAGNE_UPDATE_NUMERO_SUCCESS";
export const CAMPAGNE_UPDATE_NUMERO_FAIL = "CAMPAGNE_UPDATE_NUMERO_FAIL";
