import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { getUserStats } from "../../redux/actions/userActions";
import { getHistoriqueListForOneUser } from "../../redux/actions/appelActions";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { createBudget } from "../../redux/actions/budgetActions";
const CreateBudget = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const userLogin = useSelector((state) => state.userLogin);
    const { userInfo } = userLogin;
    const [dataBudget, setDataBudget] = useState({
        client: "",
        type: 'ARGENT',
        valeur: null
    });
    const [selectTypeBudget, setSelectTypeBudget] = useState('ARGENT');
    const [showError, setShowError] = useState(false);
    const { _id } = userInfo.data.user;
    useEffect(() => {
        if (!userInfo) {
            navigate("/login");
        } else {
            dispatch(getUserStats(id));
            dispatch(
                getHistoriqueListForOneUser(
                    id,
                    "fields=_id,clientId,calldate,billsec,dst,disposition"
                )
            );
        }
    }, [dispatch, navigate, userInfo, id]);
    const handleSave = () => {
        if (!dataBudget.valeur ) {
            setShowError(true);
        } else {
            dispatch(createBudget(dataBudget));
            props.onHide();
        }
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            className="modal-create-budget"
            centered
        >
            <Modal.Header className="modal-create-budget-header">
                <Modal.Title id="contained-modal-title-vcenter">
                    CRÉER UN BUDGET
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-create-budget-body">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Type
                        </Form.Label>
                        <Col sm="10">
                            <Form.Select size="lg" onChange={(e) => setSelectTypeBudget(e.target.value)}>
                                <option value='ARGENT' >Argent</option>
                                <option value='TEMPS'>Temps</option>
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                        <Form.Label column sm="2">
                            Valeur
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Valeur du budget" onChange={(e) => {
                                setDataBudget({ client: _id, type: selectTypeBudget, valeur: parseInt(e.target.value) })
                                setShowError(false);
                            }} />
                            {showError && (
                                <div className="alert alert-danger mt-2">
                                    Le champ 'Valeur du budget' ne peut pas être vide.
                                </div>
                            )}
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className="modal-create-budget-footer d-flex justify-content-center">
                <Button onClick={props.onHide} className="btn-create-budget">Annuler</Button>
                <Button onClick={handleSave} className="btn-create-budget">Enregistrer</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CreateBudget;
