import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import UnlinkIcon from "../../assets/images/unlink-solid.svg";
import CustomCard from "../../components/Card/Card";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import moment from "moment";
import FiltreHistorique from "./FiltreHistorique";
import { searchOptimizedData } from "../../utils/recherche";
import { useToasts } from "react-toast-notifications";
import {
  getUserStats,
  getUserDetails,
  updateUser,
} from "../../redux/actions/userActions";
import { getHistoriqueListForOneUser } from "../../redux/actions/appelActions";
import {
  getNumeroList,
  updateOneNumero,
} from "../../redux/actions/extensionActions";
import FluxAppel from "./FluxAppel";
const Agent = () => {
  const { addToast } = useToasts();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const [filtreBadge, setFiltreBadge] = useState([]);
  const [filtreAppel, setFiltreAppel] = useState([]);
  const [dataCell, setDataCell] = useState();
  const [allHistorique, setAllHistorique] = useState([]);
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [endDate, setEndDate] = useState(startDateFormate);
  const [startDate, setStartDate] = useState(dateFormateeOneWeeBefore);
  const [selected, setSelected] = useState("");
  const [bool, setBool] = useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userStats = useSelector((state) => state.userStats);
  const userUpdate = useSelector((state) => state.userUpdate);
  const successUpdateUser = userUpdate.success;
  const errorUpdateUser = userUpdate.error;
  const { loading, error, stats } = userStats;
  const agentHistoriques = useSelector((state) => state.agentHistoriques);
  const { agent_historique_loading, agent_historique_error, historiques } =
    agentHistoriques;
  const userDetails = useSelector((state) => state.userDetails);
  const { user_details_loading, user_details_error } = userDetails;
  const agentNumero = useSelector((state) => state.agentNumero);
  const { numero_loading, numero_error } = agentNumero;

  const fieldsToFilter = ["billsec", "calldate", "dst", "src", "sourceNum"];
  const queryParameters = new URLSearchParams(window.location.search);

  const redirect = queryParameters ? queryParameters.get("from") : "/";
  //gestion session
  if (!userInfo?.token) {
    navigate("/login");
  }
  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //format badge tabulator
  const badgeBtn = (cell) => {
    const value = cell.getValue();
    const icon = document.createElement("icon");
    if (value == 1) {
      icon.className = "bi bi-star";
      icon.style = "color:gray";
    } else if (value == 2) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:#FFC436";
    } else if (value == 3) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:#00e7eb";
    } else if (value == 4) {
      icon.className = "bi bi-star-fill";
      icon.style = "color:orange";
    } else if (value == 5) {
      icon.className = "bi bi-exclamation-circle-fill";
      icon.style = "color:#EB455F";
    } else if (value == 6) {
      icon.className = "bi bi-check-circle-fill";
      icon.style = "color:#6cc070";
    } else {
      icon.className = "bi bi-star";
      icon.style = "color:gray";
    }
    return icon;
  };

  //format badge tabulator
  const statutBtn = (cell) => {
    const value = cell.getValue();
    const icon = document.createElement("icon");
    if (value == 1) {
      icon.className = "bi bi-telephone-inbound-fill";
      icon.style = "color:#6cc070";
    } else if (value == 2) {
      icon.className = "bi bi-telephone-outbound-fill";
      icon.style = "color:#00e7eb";
    } else if (value == 3) {
      icon.className = "bi bi-telephone-x-fill";
      icon.style = "color:#EB455F";
    } else if (value == 4) {
      icon.className = "bi bi-voicemail";
      icon.style = "color:orange";
    } else {
      icon.className = "bi bi-telephone-x-fill";
      icon.style = "color:red";
    }
    return icon;
  };
  //boutton parametrage
  const buttonParametrage = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-plus";
    btn.appendChild(icon);
    btn.setAttribute("data-bs-toggle", "offcanvas");
    btn.setAttribute("data-bs-target", "#flux");
    btn.addEventListener("click", function () {
      setDataCell(data);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //detacher un numéro
  const handleUnlink = (_id, index) => {
    updateOneNumero(_id, { campagne: null, agent: null }, userInfo.token)
      .then((res) => {
        addToast("Numéro retiré avec succès.", {
          appearance: "success",
          autoDismiss: true,
        });
        setBool(!bool);
      })
      .catch((err) => {
        addToast("Une erreur s'est produit!", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(err);
      });
  };
  //detacher un campagne
  const handleUnlinkCampagne = (_id, index) => {
    const newData = userDetails?.userDetails[0].campagnes.filter(
      (el) => el._id != _id
    );
    dispatch(updateUser(id, { campagnes: newData }));

    setBool(!bool);
  };

  const colonneHistorique = [
    { title: "Statut", width: 100, field: "statutAppel", formatter: statutBtn },
    { title: "Source", minWidth: 150, field: "src", formatter: simpleInput },
    {
      title: "Destination",
      minWidth: 150,
      field: "dst",
      formatter: simpleInput,
    },
    {
      title: "Numéro",
      minWidth: 150,
      field: "sourceNum",
      formatter: simpleInput,
    },
    {
      title: "Appel du",
      minWidth: 150,
      field: "calldate",
      formatter: simpleInput,
    },
    { title: "Durée", width: 100, field: "billsec", formatter: simpleInput },
    { title: "Badge", width: 100, field: "badge", formatter: badgeBtn },
    { title: "Plus", width: 100, field: "", formatter: buttonParametrage },
  ];

  useEffect(() => {
    if (historiques?.data) {
      //console.log(historiques.data);
      setAllHistorique(historiques.data);
    }
  }, [historiques]);
  // Bloc 1 : Appel à getUserStats
  useEffect(() => {
    dispatch(
      getUserStats(
        id,
        `fields=_id,billsec&calldate[gte]=${startDate}&calldate[lte]=${endDate}${
          filtreBadge.length > 0 ? `&badge=${filtreBadge}` : ""
        }${filtreAppel.length > 0 ? `&statutAppel=${filtreAppel}` : ""}`
      )
    );
  }, [startDate, endDate, selected, filtreBadge, filtreAppel]);
  // Bloc 2 : Appel à getExtensionList
  useEffect(() => {
    const momentDateDebut = moment(startDate, "YYYY-MM-DD HH:mm:ss");
    const momentDateFin = moment(endDate, "YYYY-MM-DD HH:mm:ss");
    if (momentDateDebut.isSameOrBefore(momentDateFin)) {
      dispatch(
        getHistoriqueListForOneUser(
          id,
          `fields=_id,note,recordingfile,clientId,calldate,billsec,src,dst,disposition,badge,statutAppel,sourceNum,pointeurNum&sort=-calldate&calldate[gte]=${startDate}&calldate[lte]=${endDate}${
            filtreBadge.length > 0 ? `&badge=${filtreBadge}` : ""
          }${filtreAppel.length > 0 ? `&statutAppel=${filtreAppel}` : ""}`
        )
      );
    } else {
      alert("la date de debut doit etre inférieur ou égal à la date de fin");
    }
  }, [id, startDate, endDate, filtreBadge, filtreAppel]);
  // Bloc 3 : Appel à d'autres actions
  useEffect(() => {
    dispatch(getNumeroList(`clientId=${id}&archive=false&fields=_id,numero`));
    dispatch(getUserDetails([id]));
  }, [id, bool]);
  //gestion des notifications
  useEffect(() => {
    if (agent_historique_error) {
      addToast(agent_historique_error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (user_details_error) {
      addToast(user_details_error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (errorUpdateUser) {
      addToast(errorUpdateUser, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (successUpdateUser) {
      addToast("Campagne retirée avec succès.", {
        appearance: "success",
        autoDismiss: true,
      });
    }
  }, [
    agent_historique_error,
    user_details_error,
    errorUpdateUser,
    successUpdateUser,
  ]);
  return (
    <>
      <Container>
        {/* {agent_historique_error && (
          <Row>
            <Message variant="danger">{agent_historique_error}</Message>
          </Row>
        )} */}
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-3  pt-3">
            <h5 className="current-route text-capitalize">
              {redirect && redirect === "/" ? (
                <Link to={"/"}>/ Accès </Link>
              ) : (
                <></>
              )}
              / Agent /
            </h5>
          </Col>
          <Col className="col-3">
            <h5 className="d-flex justify-content-end">
              {userDetails?.userDetails[0]?.nom &&
                userDetails.userDetails[0].nom}
              {""}
              {userDetails?.userDetails[0]?.prenom &&
                userDetails.userDetails[0].prenom}
            </h5>
          </Col>
        </Row>
        <Row className="d-flex flex-wrap justify-content-between mt-3 mb-5">
          <CustomCard
            cardTitle="Numéros"
            cardValeur={
              loading ? (
                <Loader />
              ) : stats && stats.numeros ? (
                stats.numeros.toString()
              ) : (
                "0"
              )
            }
          />
          <CustomCard
            cardTitle="Durée appel"
            cardValeur={
              loading ? (
                <Loader />
              ) : stats && stats.nbHeureAppel ? (
                stats.nbHeureAppel
              ) : (
                "0"
              )
            }
          />
          <CustomCard
            cardTitle="Nb Appels"
            cardValeur={
              loading ? (
                <Loader />
              ) : historiques?.data && historiques.data.length > 0 ? (
                `${historiques.data.length}`
              ) : (
                "0"
              )
            }
          />
        </Row>

        <Row>
          <Col className="col-12 pl-0">
            <div className="shadow" style={{ width: "100%" }}>
              <>
                {/*  {agent_historique_error && (
                  <Row>
                    <Message variant="danger">{agent_historique_error}</Message>
                  </Row>
                )} */}
                {historiques && historiques.data?.length < 1 && (
                  <Message>
                    Aucun appel entre {startDate.split("T")[0]} et{" "}
                    {endDate.split("T")[0]}
                  </Message>
                )}
                <div className="tabHistorique" style={{ width: "100%" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      padding: "0px 0px 0px 40px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="titreTabulator">
                        Historiques -{" "}
                        {historiques?.data?.length
                          ? historiques.data.length
                          : 0}{" "}
                        appel(s)
                      </span>
                    </div>
                    <div
                      className="champRecherche"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <FiltreHistorique
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        filtreBadge={filtreBadge}
                        setFiltreBadge={setFiltreBadge}
                        filtreAppel={filtreAppel}
                        setFiltreAppel={setFiltreAppel}
                      />
                      <button
                        className="btn btn-sm"
                        style={{
                          backgroundColor: "#6cc070 ",
                          color: "white",
                          marginRight: "10px",
                          display: "flex",
                          alignItems: "center",
                        }}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#filtre"
                      >
                        <i
                          class="bi bi-filter"
                          style={{ fontSize: "14px" }}
                        ></i>
                        <span>Filtres</span>{" "}
                      </button>
                      <span
                        style={{
                          padding: "8px 12px 8px 12px",
                          backgroundColor: "rgba(226, 232, 240, 0.6)",
                          borderRadius: "8px",
                          height: "40px",
                          display: "flex",
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#818CF8"
                            style={{ width: "18px", height: "18px" }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </span>
                        <input
                          type="text"
                          className="rechercheTabulator"
                          placeholder="Recherche"
                          onChange={(ev) => {
                            const dataSearch = searchOptimizedData(
                              ev.target.value,
                              allHistorique,
                              fieldsToFilter
                            );
                            setAllHistorique(dataSearch);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  {agent_historique_loading ? (
                    <Loader />
                  ) : (
                    <>
                      <ReactTabulator
                        data={allHistorique ? allHistorique : []}
                        columns={colonneHistorique}
                        layout={"fitColumns"}
                        // events={{ cellEdited: planningEdited }}
                        options={{
                          locale: true,
                          pagination: "local",
                          paginationSize: 5,
                          langs: {
                            fr: {
                              pagination: {
                                first: "Premier",
                                first_title: "Première",
                                last: "Dernier",
                                last_title: "Dernière",
                                prev: "Précédent",
                                prev_title: "Précédente",
                                next: "Suivant",
                                next_title: "Page suivante",
                                page_size: "Taille de page",
                                page_title: "Afficher la page",
                                all: "Tous"
                              },
                            },
                          },
                        }}
                      />
                    </>
                  )}
                </div>
              </>
            </div>
          </Col>
        </Row>
        <FluxAppel dataCell={dataCell} userInfo={userInfo} />

        <Row className="mt-5">
          <Col className="col-6">
            {numero_error && <Message variant="danger">{error}</Message>}
            {numero_loading ? (
              <Loader />
            ) : (
              <Card>
                <h2 className="current-route m-3">Numéros personnels:</h2>
                <ListGroup>
                  <ListGroup.Item>
                    <Row>
                      <Col className="col-9">Extensions</Col>
                      <Col className="col-3">Delier</Col>
                    </Row>
                  </ListGroup.Item>
                  {userDetails?.userDetails[0]?.agentNumeros &&
                    userDetails.userDetails[0].agentNumeros.map(
                      (numero, index) => {
                        return (
                          <ListGroup.Item key={index}>
                            <Row>
                              <Col className="col-9">
                                {numero.numero || "-"}
                              </Col>
                              <Col className="col-3">
                                <img
                                  src={UnlinkIcon}
                                  type="button"
                                  onClick={() =>
                                    handleUnlink(numero._id, index)
                                  }
                                  alt="unlink icon"
                                />
                              </Col>
                            </Row>
                          </ListGroup.Item>
                        );
                      }
                    )}

                  <ListGroup.Item>
                    <Row>
                      <Col className="col-12 text-center">
                        <button
                          className="btn btn-sm"
                          style={{ backgroundColor: "#61CE70", color: "white" }}
                        >
                          <Link
                            to="/numero?from=/Acces"
                            style={{ color: "white" }}
                          >
                            Ajouter un numéro
                          </Link>
                        </button>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            )}
          </Col>
          <Col className="col-6">
            <Card>
              <h2 className="current-route m-3">Campagnes:</h2>
              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col className="col-9">Campagnes</Col>
                    <Col className="col-3">Delier</Col>
                  </Row>
                </ListGroup.Item>
                {user_details_error && (
                  <Message variant="danger">{user_details_error}</Message>
                )}
                {user_details_loading ? (
                  <Loader></Loader>
                ) : (
                  userDetails.userDetails[0]?.campagnes &&
                  userDetails.userDetails[0]?.campagnes?.map(
                    (campagne, index) => {
                      return (
                        <ListGroup.Item key={index}>
                          <Row>
                            <Col className="col-9">{campagne.nom}</Col>
                            <Col className="col-3">
                              <img
                                src={UnlinkIcon}
                                type="button"
                                onClick={() =>
                                  handleUnlinkCampagne(campagne._id, index)
                                }
                                alt="unlink icon"
                              />
                            </Col>
                          </Row>
                        </ListGroup.Item>
                      );
                    }
                  )
                )}
                <ListGroup.Item>
                  <Row>
                    <Col className="col-12 text-center">
                      <button
                        className="btn btn-sm"
                        style={{ backgroundColor: "#61CE70", color: "white" }}
                      >
                        <Link
                          to="/campagne?from=/Acces"
                          style={{ color: "white" }}
                        >
                          Ajouter un campagne
                        </Link>
                      </button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Agent;
