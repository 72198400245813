import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container } from "react-bootstrap";
import Login from "./screens/Login/Login";
import Home from "./screens/Home/Home";
import Header from "./components/Header/Header";
import { useSelector } from "react-redux";
import Agent from "./screens/Agent/Agent";
import Credit from "./screens/Credit";
import Alerte from "./screens/Alerte";
import "./App.css";
import Budget from "./screens/Budget/Budget";
import Demandes from "./screens/Demandes/Demande";
import CreateAgent from "./screens/Agent/createAgent";
import Numero from "./screens/Numero/Numero";
import Campagne from "./screens/Campagne/Campagne";
import Trunk from "./screens/Trunk/Trunk";
import { ToastProvider } from "react-toast-notifications";

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  window.onerror = function (message, source, lineno, colno, error) {
    // Log ou gérez l'erreur comme nécessaire
    console.error(message, source, lineno, colno, error);

    // Retourne true pour empêcher l'affichage de l'erreur dans l'interface utilisateur
    return true;
  };
  return (
    <ToastProvider>
      <BrowserRouter>
        {userInfo && <Header />}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/agent/:id" element={<Agent />} />
            <Route path="/" element={<Home />} exact />
            <Route path="/credit" element={<Credit />} exact />
            <Route path="/alerte" element={<Alerte />} exact />
            <Route path="/budget" element={<Budget />} exact />
            <Route path="/createAgent" element={<CreateAgent />} exact />
            <Route path="/demandes" element={<Demandes />} exact />
            <Route path="/numero" element={<Numero />} exact />
            <Route path="/campagne" element={<Campagne />} exact />
            <Route path="/Trunk" element={<Trunk />} exact />
            <Route path="*" element={<Login />} />

          </Routes>
      </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
