import {
  TRUNK_LIST_REQUEST,
  TRUNK_LIST_SUCCESS,
  TRUNK_LIST_FAIL,
  TRUNK_GLOBAL_INFO_REQUEST,
  TRUNK_GLOBAL_INFO_SUCCESS,
  TRUNK_GLOBAL_INFO_FAIL,
} from "../constants/trunkConstants";
import axios from "axios";
export const getTrunkListForOneUser = (query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRUNK_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/trunks?${query}`,
      config
    );
    dispatch({
      type: TRUNK_LIST_SUCCESS,
      payload: data?.data ? data.data : [],
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: TRUNK_LIST_FAIL,
      payload: message,
    });
  }
};

export const getTrunkGlobalInfo = (query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: TRUNK_GLOBAL_INFO_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/trunks/stats?${query}`,
      config
    );
    dispatch({
      type: TRUNK_GLOBAL_INFO_SUCCESS,
      payload: data?.data ? data.data : [],
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: TRUNK_GLOBAL_INFO_FAIL,
      payload: message,
    });
  }
};
export const updateOneTrunk = async (id, datas, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/trunks/${id}`,
      datas,
      config
    );

    return data;
  } catch (error) {
    return error;
  }
};
export const getListNoire = async (id,query, token) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const { data } = await axios
      .get(
        `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/blacklistedNumbers?trunk=${id}&${query}`,
        config
      )
      .then((res) => {
        return res;
      });

    return data;
  } catch (error) {
    return error;
  }
};
export const addListNoire = async (token, listToAdd) => {
  console.log(listToAdd);
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/blacklistedNumbers/createMany`,
      listToAdd,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteListNoire = async (token, listToDelete) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const data = await axios.patch(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/blacklistedNumbers/deleteMany`,
      listToDelete,
      config
    );
    return data;
  } catch (error) {
    return error;
  }
};
