export const BUDGET_LIST_REQUEST = "BUDGET_LIST_REQUEST";
export const BUDGET_LIST_SUCCESS = "BUDGET_LIST_SUCCESS";
export const BUDGET_LIST_FAIL = "BUDGET_LIST_FAIL";

export const BUDGET_CREATE_REQUEST = "BUDGET_CREATE_REQUEST";
export const BUDGET_CREATE_SUCCESS = "BUDGET_CREATE_SUCCESS";
export const BUDGET_CREATE_FAIL = "BUDGET_CREATE_FAIL";
export const BUDGET_CREATE_RESET = "BUDGET_CREATE_RESET";

export const BUDGET_DELETE_REQUEST = "BUDGET_DELETE_REQUEST";
export const BUDGET_DELETE_SUCCESS = "BUDGET_DELETE_SUCCESS";
export const BUDGET_DELETE_FAIL = "BUDGET_DELETE_FAIL";
