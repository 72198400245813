import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import "./style.css";
import {
  getListCampagneAndAgent,
  updateOneNumero,
  updateAudioOneNumero,
} from "../../redux/actions/extensionActions";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
const ModifyCampagne = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  if (!userInfo?.token) {
    navigate("/login");
  }
  const listAgentAndCampagne = useSelector(
    (state) => state.listAgentAndCampagne
  );
  const { loading_list, error, data } = listAgentAndCampagne;
  const [selectedItem, setSelectedItem] = useState("");
  const [idSelectedItem, setIdSelectedItem] = useState("");
  const [typeAppel, setTypeAppel] = useState(1);
  const [dureInterval, setDureInterval] = useState(5);
  const [type, setType] = useState(1);
  const [allowTranscript, setAllowTranscript] = useState(false);
  const [audioRepondeur, setAudioRepondeur] = useState();
  const [isAudioRepondeur, setIsAudioRepondeur] = useState(false);
  const handleSave = () => {
    /* updateOneNumero(props.info) */
    if (audioRepondeur) {
      const formData = new FormData();
      formData.append("audio", audioRepondeur);
      updateAudioOneNumero(props.infoNum?._id, formData, userInfo.token)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (type == "campagne") {
      const data = {
        campagne: idSelectedItem,
        modeAppelType: typeAppel,
        timeout: dureInterval,
        enableTranscrib: allowTranscript,
      };
      updateOneNumero(props.infoNum?._id, data, userInfo.token)
        .then((res) => {
          if (res.data.data) {
            props.cell?.getRow().update({
              ...res.data.data,
              assignement: verificationAssignment(res.data.data),
            });
            addToast("Enregistrement avec succès.", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          addToast("Une erreur s'est produite", {
            appearance: "error",
            autoDismiss: true,
          });
        });
    }
    if (type == "agent") {
      const data = {
        agent: idSelectedItem,
        enableTranscrib: allowTranscript,
      };
      updateOneNumero(props.infoNum?._id, data, userInfo.token)
        .then((res) => {
          if (res?.data?.data) {
            props.cell?.getRow().update({
              ...res.data.data,
              assignement: verificationAssignment(res.data.data),
            });
            addToast("Enregistrement avec succès.", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          addToast(error, {
            appearance: "Une erreur s'est produite",
            autoDismiss: true,
          });
        });
    }
    if (!type) {
      const data = {
        campagne: null,
        agent: null,
        enableTranscrib: allowTranscript,
      };
      updateOneNumero(props.infoNum?._id, data, userInfo.token)
        .then((res) => {
          if (res.data?.data) {
            props.cell?.getRow().update({
              ...res.data.data,
              assignement: verificationAssignment(res.data.data),
            });
            addToast("Enregistrement avec succès.", {
              appearance: "success",
              autoDismiss: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          addToast(error, {
            appearance: "Une erreur s'est produite",
            autoDismiss: true,
          });
        });
    }
    props.onHide();
  };
  const verificationAssignment = (numero) => {
    if (!numero.agent && !numero.campagne) {
      return "Disponible";
    }
    if (numero.agent && !numero.campagne) {
      return `${numero.agent?.nom && numero.agent.nom} ${
        numero.agent?.prenom && numero.agent.prenom
      }`;
    }
    if (!numero.agent && numero.campagne) {
      return `${numero.campagne?.nom ? numero.campagne.nom : "Non défini"}`;
    }
    if (numero.agent && numero.campagne) {
      return "ce numéro rencontre un problème";
    }
  };
  const verificationIdAssignment = (numero) => {
    if (!numero.agent && !numero.campagne) {
      setIdSelectedItem(null);
      setType(null);
    }
    if (numero.agent && !numero.campagne) {
      setIdSelectedItem(numero.agent._id);
      setType("agent");
    }
    if (!numero.agent && numero.campagne) {
      setIdSelectedItem(numero.campagne._id);
      setDureInterval(numero.timeout ? numero.timeout : 5);
      setType("campagne");
      setTypeAppel(numero.modeAppelType);
    }
    if (numero.agent && numero.campagne) {
      setIdSelectedItem(null);
      setType(null);
    }
  };

  useEffect(() => {
    if (props.infoNum) {
      setAudioRepondeur(null);
      setAllowTranscript(props.infoNum?.enableTranscrib);
      setSelectedItem(verificationAssignment(props.infoNum));
      verificationIdAssignment(props.infoNum);
      if (props.infoNum.noAnswerAudioFilename) {
        console.log(props.infoNum);
        setIsAudioRepondeur(true);
      } else {
        setIsAudioRepondeur(false);
      }
    }
  }, [props.infoNum]);
  useEffect(() => {
    dispatch(getListCampagneAndAgent());
  }, [userInfo]);

  return (
    <Modal {...props}>
      <Modal.Header className="modal-create-budget-header">
        <h5>
          {" "}
          Affectation numéro {props.infoNum?.numero && props.infoNum?.numero}
        </h5>
      </Modal.Header>
      {loading_list && <Loader />}
      <Modal.Body className="modal-create-budget-body">
        {error && (
          <Row>
            <Message variant="danger">{error}</Message>
          </Row>
        )}
        <div
          class="mb-3 mt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label class="form-label">Agent / Campagne / disponible:</label>
          <button
            className="btn dropdown"
            data-bs-toggle="dropdown"
            style={{ backgroundColor: "lightgray", position: "relative" }}
          >
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {selectedItem}
            </span>
            <i
              class="bi bi-chevron-down"
              style={{ position: "absolute", top: "2px", right: "20px" }}
            ></i>
          </button>
          <ul
            className="dropdown-menu"
            style={{ maxHeight: "400px", overflowY: "scroll", width: "100%" }}
          >
            <li
              onClick={(ev) => {
                setSelectedItem("Disponible");
                setIdSelectedItem(null);
                setType(null);
              }}
              style={{ cursor: "pointer" }}
            >
              <a className="dropdown-item">Disponible</a>
            </li>
            <li
              style={{ backgroundColor: "lightgray" }}
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
              }}
            >
              <a
                className="dropdown-item"
                style={{ backgroundColor: "lightgray" }}
              >
                Listes agents
              </a>
            </li>
            {data?.agents && data.agents.length > 0 ? (
              <>
                {data.agents.map((el) => {
                  return (
                    <>
                      <li
                        onClick={(ev) => {
                          setSelectedItem(`${el?.nom && el.nom}
                        ${el?.prenom && el.prenom}`);
                          setIdSelectedItem(el._id);
                          setType("agent");
                        }}
                        style={{ cursor: "pointer", textAlign: "left" }}
                      >
                        <a className="dropdown-item">
                          -{"  "}
                          {el?.nom && el.nom} {el?.prenom && el.prenom}
                        </a>
                      </li>
                    </>
                  );
                })}
              </>
            ) : (
              <>Aucun agent disponible</>
            )}
            <li
              style={{ backgroundColor: "lightgray" }}
              onClick={(ev) => {
                ev.stopPropagation();
                ev.preventDefault();
              }}
            >
              <a
                className="dropdown-item"
                style={{ backgroundColor: "lightgray" }}
              >
                Listes campagnes
              </a>
            </li>
            {data?.campagnes && data.campagnes.length > 0 ? (
              <>
                {data.campagnes.map((el) => {
                  return (
                    <>
                      <li
                        onClick={(ev) => {
                          setSelectedItem(`${el?.nom ? el.nom : "Non défini"}`);
                          setIdSelectedItem(el._id);
                          setType("campagne");
                        }}
                        style={{ cursor: "pointer", textAlign: "left" }}
                      >
                        <a className="dropdown-item">
                          {el?.nom ? el.nom : "Non défini"}
                        </a>
                      </li>
                    </>
                  );
                })}
              </>
            ) : (
              <>Aucun campagne disponible</>
            )}
          </ul>
        </div>
        <div
          class="mb-3 mt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label class="form-label">Trascription des audios</label>
          <button
            className="btn dropdown"
            data-bs-toggle="dropdown"
            style={{ backgroundColor: "lightgray", position: "relative" }}
          >
            <span style={{ fontSize: "16px", fontWeight: "600" }}>
              {allowTranscript && "activé"}
              {!allowTranscript && "désactivé"}
            </span>
            <i
              class="bi bi-chevron-down"
              style={{ position: "absolute", top: "2px", right: "20px" }}
            ></i>
          </button>
          <ul className="dropdown-menu" style={{ width: "100%" }}>
            <li
              onClick={(ev) => {
                setAllowTranscript(true);
              }}
              style={{ cursor: "pointer" }}
            >
              <a className="dropdown-item">Activé</a>
            </li>
            <li
              onClick={(ev) => {
                setAllowTranscript(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <a className="dropdown-item">Désactivé</a>
            </li>
          </ul>
        </div>
        {type == "campagne" && (
          <>
            <div
              class="mb-3 mt-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label class="form-label">
                Type d'appel entrante pour cette campagne:
              </label>
              <button
                className="btn dropdown"
                data-bs-toggle="dropdown"
                style={{ backgroundColor: "lightgray", position: "relative" }}
              >
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {typeAppel == 1 && "Simultané"}
                  {typeAppel == 2 && "Séquentiel"}
                </span>
                <i
                  class="bi bi-chevron-down"
                  style={{ position: "absolute", top: "2px", right: "20px" }}
                ></i>
              </button>
              <ul className="dropdown-menu" style={{ width: "100%" }}>
                <li
                  onClick={(ev) => {
                    setTypeAppel(1);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <a className="dropdown-item">Simultané</a>
                </li>
                <li
                  onClick={(ev) => {
                    setTypeAppel(2);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <a className="dropdown-item">Séquentiel</a>
                </li>
              </ul>
            </div>
            {typeAppel == 2 && (
              <>
                <div class="mb-3 mt-3">
                  <label class="form-label">Durée d'interval (secondes):</label>
                  <input
                    type="number"
                    class="form-control"
                    placeholder="intervalle"
                    defaultValue={dureInterval}
                    onChange={(ev) => setDureInterval(ev.target.value)}
                  />
                </div>
              </>
            )}
          </>
        )}
        {isAudioRepondeur ? (
          <>
            <div
              class="mb-3 mt-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label class="form-label">Fichier audio pour le répondeur</label>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  titre:{" "}
                  {props.infoNum?.noAnswerAudioFilename
                    ? props.infoNum?.noAnswerAudioFilename
                    : "Non défini"}
                </span>
                <i
                  class="bi bi-pencil-square"
                  onClick={(ev) => setIsAudioRepondeur(false)}
                ></i>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              class="mb-3 mt-3"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label class="form-label">
                Fichier audio pour le répondeur (.wav,.mp3)
              </label>
              <input
                type="file"
                onChange={(ev) => {
                  ev.preventDefault();
                  setAudioRepondeur(ev.target.files[0]);
                }}
                className="form-control"
                accept=".wav,.mp3"
              />
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-danger"
          onClick={(ev) => {
            props.onHide();
          }}
        >
          Fermer
        </button>
        <button
          className="btn"
          style={{
            backgroundColor: "#61CE70 ",
            color: "white",
            marginLeft: "10px",
          }}
          onClick={() => {
            handleSave();
          }}
        >
          Enregistrer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModifyCampagne;
