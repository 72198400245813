import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension";

import {listAgentAndCampagneReducer, agentNumeroReducer, assignAccessReducer, assignNumeroReducer, numeroAccessUpdateReducer } from "./reducers/extensionReducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
  userInfoReducer,
  assignCampaignReducer,
} from "./reducers/userReducers";

import { agentHistoriquesReducer } from "./reducers/appelReducers";

import {
  campagneAccessUpdateReducer,
  campagneAssignAccessReducer,
  campagneAssignNumeroReducer,
  campagneDetailsReducer,
  campagneUpdateNumeroReducer,
  compteInfoReducer,
  compagneListReducer,
  createCampagneReducer,
} from "./reducers/compagneReducers";

import { creditReducer } from "./reducers/creditReducers";
import {
  budgetReducer,
  createBudgetReducer,
  deleteBudgetReducer,
} from "./reducers/budgetReducers";
import { cancelRequestReducer, createRequestReducer, getAllRequestReducers, requestDetailsReducer, requestUpdateReducer } from "./reducers/demandeReducers";
import { trunkReducer,trunkGlobalInfoReducer } from "./reducers/trunkReducers";
const reducer = combineReducers({
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  compteInfo: compteInfoReducer,
  userStats: userInfoReducer,
  agentHistoriques: agentHistoriquesReducer,
  agentNumero: agentNumeroReducer,
  creditList: creditReducer,
  budgetList: budgetReducer,
  createBudget: createBudgetReducer,
  deleteBudget: deleteBudgetReducer,
  compagneList: compagneListReducer,
  assignCampaign: assignCampaignReducer,
  assignAccess: assignAccessReducer,
  allRequest:getAllRequestReducers,
  createRequest: createRequestReducer,
  createCampagne: createCampagneReducer,
  cancelRequest:cancelRequestReducer,
  requestDetail:requestDetailsReducer,
  requestUpdate:requestUpdateReducer,
  numeroAccess:numeroAccessUpdateReducer,
  campagneAccess:campagneAccessUpdateReducer,
  campagneAssignAccess:campagneAssignAccessReducer,
  campagneAssignNumero:campagneAssignNumeroReducer,
  assignNumero:assignNumeroReducer,
  campagneDetails:campagneDetailsReducer,
  updateNumeroCampagne:campagneUpdateNumeroReducer,
  listAgentAndCampagne:listAgentAndCampagneReducer,
  listTrunk:trunkReducer,
  trunkGlobalInfo:trunkGlobalInfoReducer
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const initialState = {
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
