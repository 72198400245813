export const TRUNK_LIST_REQUEST = "TRUNK_LIST_REQUEST";
export const TRUNK_LIST_SUCCESS = "TRUNK_LIST_SUCCESS";
export const TRUNK_LIST_FAIL = "TRUNK_LIST_FAIL";


export const TRUNK_UPDATE_REQUEST = "TRUNK_UPDATE_REQUEST";
export const TRUNK_UPDATE_SUCCESS = "TRUNK_UPDATE_SUCCESS";
export const TRUNK_UPDATE_FAIL = "TRUNK_UPDATE_FAIL";

export const TRUNK_GLOBAL_INFO_REQUEST = "TRUNK_GLOBAL_INFO_REQUEST";
export const TRUNK_GLOBAL_INFO_SUCCESS = "TRUNK_GLOBAL_INFO_SUCCESS";
export const TRUNK_GLOBAL_INFO_FAIL = "TRUNK_GLOBAL_INFO_FAIL";