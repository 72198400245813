
import {
  DEMANDE_LIST_FAIL,
  DEMANDE_LIST_REQUEST,
  DEMANDE_LIST_SUCCESS,
  DEMANDE_CREATE_FAIL,
  DEMANDE_CREATE_REQUEST,
  DEMANDE_CREATE_SUCCESS,
  DEMANDE_CANCEL_REQUEST,
  DEMANDE_CANCEL_SUCCESS,
  DEMANDE_CANCEL_FAIL,
  DEMANDE_DETAIL_REQUEST,
  DEMANDE_DETAIL_SUCCESS,
  DEMANDE_DETAIL_FAIL,
  DEMANDE_UPDATE_REQUEST,
  DEMANDE_UPDATE_SUCCESS,
  DEMANDE_UPDATE_FAIL,
} from "../constants/demandeConstants";

export const getAllRequestReducers = (state = { allRequests: [] }, action) => {
  switch (action.type) {
    case DEMANDE_LIST_REQUEST:
      return { request_loading: true };
    case DEMANDE_LIST_SUCCESS:
      return {
        request_loading: false,
        allRequests: action.payload,
      };
    case DEMANDE_LIST_FAIL:
      return {
        request_loading: false,
        request_error: action.payload,
      };
    default:
      return state;
  }
};
export const createRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case DEMANDE_CREATE_REQUEST:
      return { loading: true };
    case DEMANDE_CREATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case DEMANDE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const cancelRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case DEMANDE_CANCEL_REQUEST:
      return { cancel_request_loading: true };
    case DEMANDE_CANCEL_SUCCESS:
      return { cancel_request_loading: false, success: true };
    case DEMANDE_CANCEL_FAIL:
      return { cancel_request_loading: false, error: action.payload };
    default:
      return state;
  }
};
export const requestDetailsReducer = (state = { requestDetails: [] }, action) => {
  switch (action.type) {
    case DEMANDE_DETAIL_REQUEST:
      return { ...state, request_loading: true };
    case DEMANDE_DETAIL_SUCCESS:
      return { request_loading: false, requestDetails: action.payload };
    case DEMANDE_DETAIL_FAIL:
      return {
        request_loading: false,
        request_error: action.payload,
      };
    default:
      return state;
  }
};
export const requestUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case DEMANDE_UPDATE_REQUEST:
      return { loading: true };
    case DEMANDE_UPDATE_SUCCESS:
      return { loading: false, success:true };
    case DEMANDE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};