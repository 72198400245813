import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import { Col, Row, Form } from "react-bootstrap";
import Select from "react-select";
import { updateOneTrunk } from "../../redux/actions/trunkActions";
const ModalParametrage = (props) => {
  const listBudget = [
    {
      value: 50,
      label: "50€",
    },
    {
      value: 100,
      label: "100€",
    },
    {
      value: 500,
      label: "500€",
    },
  ];
  const [alerteMinute, setAlerteMinute] = useState(
    props.trunk?.timeBudget ? (props.trunk.timeBudget / 60).toFixed(0) : 0
  );
  const { addToast } = useToasts();
  const [selectedBudget, setSelectedBudget] = useState(
    props.trunk?.moneyBudget
      ? props.trunk?.moneyBudget.map((el) => {
          return {
            value: el,
            label: `${el}€`,
          };
        })
      : []
  );
  const handleSave = () => {
    const budgetFormated = selectedBudget.map((el) => el.value);
    const data = {
      moneyBudget: budgetFormated,
      timeBudget: alerteMinute * 60,
    };
    updateOneTrunk(props.trunk._id, data, props.token)
      .then((res) => {
        addToast("Modification avec succès.", {
          appearance: "success",
          autoDismiss: true,
        });
        props.onHide();
        props.setBool(!props.bool);
      })
      .catch((err) => {
        addToast("Une erreur s'est produite", {
          appearance: "error",
          autoDismiss: true,
        });
        console.log(err);
        props.onHide();
      });
  };
//Modal parametrage
  return (
    <>
      <Modal {...props}>
        <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Paramétrage </h5>
          <span onClick={props.onHide}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="4" style={{ display: "flex", alignItems: "center" }}>
                <Form.Label>Alerte journalier (minute):</Form.Label>
              </Col>
              <Col sm="8">
                <div className="input-group">
                  <Form.Control
                    required
                    type="number"
                    placeholder="Entrez votre quantité"
                    defaultValue={alerteMinute}
                    onChange={(e) => {
                      setAlerteMinute(e.target.value);
                    }}
                    className="custom-form-control"
                  />
                  <Form.Control.Feedback type="invalid">
                    Veuillez entrer une quantité valide.
                  </Form.Control.Feedback>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Form.Label>
                  Envoye email si la consommation a atteint:
                </Form.Label>
              </Col>
              <Col sm="12">
                <Select
                  defaultValue={selectedBudget}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  isMulti
                  options={listBudget ? listBudget : null}
                  onChange={(e) => setSelectedBudget(e)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            Fermer
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#61CE70 ",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={(ev) => {
              handleSave();
            }}
          >
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalParametrage;
