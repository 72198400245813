import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import "./style.css";
import CreateBudget from "./createBudget";
import { GoAlertFill } from "react-icons/go";
import { getBudgetList } from "../../redux/actions/budgetActions";
import { MdOutlineDeleteForever } from "react-icons/md";
import ModalConfirm from "./ModalConfirm";
import { Link } from "react-router-dom";
const Budget = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const [activeButton, setActiveButton] = useState("argent");
  const [isModalCreateBudget, setIsModalCreateBudget] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [idBudget, setIdBudget] = useState(null);
  const { userInfo } = userLogin;
  if (!userInfo?.token) {
    navigate("/login");
  }
  useEffect(() => {
    dispatch(getBudgetList());
  }, []);
  const userStats = useSelector((state) => state.userStats);
  const { loading, error, stats } = userStats;

  const budgetList = useSelector((state) => state.budgetList);
  const { budget_loading, budget_error, budgets } = budgetList;

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };
  const handleCreateBudget = () => {
    setIsModalCreateBudget(!isModalCreateBudget);
  };
  const handleConfirmDelete = (idBudget) => {
    setIdBudget(idBudget);
    setConfirmDelete(!confirmDelete);
  };
  const queryParameters = new URLSearchParams(window.location.search);

  const redirect = queryParameters ? queryParameters.get("from") : "/";
  return (
    <>
      <Container>
        {budget_error && (
          <Row>
            <Message variant="danger">{error}</Message>
          </Row>
        )}
        {budget_loading ? (
          <Loader />
        ) : (
          <>
            {budget_error && (
              <Row>
                <Message variant="danger">{error}</Message>
              </Row>
            )}
            <Row className="d-flex flex-wrap justify-content-between align-items-center my-5">
              <Col md={5}>
                <h5 className="current-route text-capitalize">
                  {redirect && redirect === "/" ? (
                    <Link to={"/"}>/ Accès </Link>
                  ) : (
                    <></>
                  )}
                  / Budget /
                </h5>
              </Col>
              <Col
                md={7}
                className="d-flex align-items-start justify-content-end"
              >
                <button
                  className="btn"
                  onClick={() => handleButtonClick("argent")}
                  style={{
                    backgroundColor:
                      activeButton == "argent" ? "#61CE70" : "gray",
                    color: "white",
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  Argent
                </button>
                <button
                  className="btn"
                  onClick={() => handleButtonClick("temps")}
                  style={{
                    backgroundColor:
                      activeButton == "temps" ? "#61CE70" : "gray",
                    color: "white",
                    borderBottomLeftRadius: 0,
                    borderTopLeftRadius: 0,
                  }}
                >
                  Temps
                </button>
                <button
                  className="btn"
                  onClick={() => handleCreateBudget()}
                  style={{
                    backgroundColor: "#61CE70",
                    color: "white",
                    marginLeft: "40px",
                  }}
                >
                  Créer Budget
                </button>
                <CreateBudget
                  show={isModalCreateBudget}
                  onHide={() => {
                    setIsModalCreateBudget(!isModalCreateBudget);
                    dispatch(getBudgetList());
                  }}
                />
                {/*   <div className="d-inline-flex align-items-start">
                  <button
                    className={`budget-btn  budget-btn-money btn-lg mb-0 mr-0 ${
                      activeButton === "argent" ? "argent-background" : ""
                    }`}
                    onClick={() => handleButtonClick("argent")}
                  >
                    Argent
                  </button>
                  <button
                    className={`budget-btn budget-btn-time btn-lg mb-0 ${
                      activeButton === "temps" ? "time-background" : ""
                    }`}
                    onClick={() => handleButtonClick("temps")}
                  >
                    Temps
                  </button>
                </div>
                <div className="ml-2">
                  <button
                    className="budget-btn budget-btn-create "
                    onClick={() => handleCreateBudget()}
                  >
                    Créer Budget
                  </button>
                  <CreateBudget
                    show={isModalCreateBudget}
                    onHide={() => {
                      setIsModalCreateBudget(!isModalCreateBudget);
                      dispatch(getBudgetList());
                    }}
                  />
                </div> */}
              </Col>
            </Row>

            <Row>
              <div className="tabHistorique" style={{ width: "100%" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    padding: "0px 0px 0px 40px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="titreTabulator">Budget</span>
                  </div>
                  <div
                    className="champRecherche"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <span
                      style={{
                        padding: "8px 12px 8px 12px",
                        backgroundColor: "rgba(226, 232, 240, 0.6)",
                        borderRadius: "8px",
                        height: "40px",
                        display: "flex",
                      }}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{ width: "18px", height: "18px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="rechercheTabulator"
                        placeholder="Recherche"
                      />
                    </span>
                  </div>
                </div>
                {activeButton === "argent" ? (
                  <Table className="table table-responsive-xl bg-white br-1">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "1rem" }}>Budget (Mois)</th>
                        <th style={{ fontSize: "1rem" }}>État du budget</th>
                        <th style={{ fontSize: "1rem" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgets &&
                        budgets.data &&
                        budgets.data.length > 0 &&
                        budgets.data.map((budget, index) => {
                          if (budget.type === "ARGENT")
                            return (
                              <tr className="alert" role="alert">
                                <td>{budget.valeur} €</td>
                                <td>
                                  {budget.pourcentage >= 100 ? (
                                    <div className="d-flex align-items-center">
                                      <span className="mr-2">
                                        {budget.alertes}
                                      </span>
                                      <GoAlertFill
                                        className="alertIcon"
                                        style={{ verticalAlign: "middle" }}
                                      />
                                      <span className="ml-2">
                                        dépassé le {budget.dateAlerte}
                                      </span>
                                    </div>
                                  ) : (
                                    `${budget.pourcentage}%`
                                  )}
                                </td>
                                <td>
                                  <MdOutlineDeleteForever
                                    className="deleteBudget"
                                    onClick={() =>
                                      handleConfirmDelete(budget._id)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <Table className="table table-responsive-xl bg-white br-1">
                    <thead>
                      <tr>
                        <th style={{ fontSize: "1rem" }}>Budget (Journée)</th>
                        <th style={{ fontSize: "1rem" }}>État du budget</th>
                        <th style={{ fontSize: "1rem" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {budgets &&
                        budgets.data &&
                        budgets.data.length > 0 &&
                        budgets.data.map((budget, index) => {
                          if (budget.type === "TEMPS")
                            return (
                              <tr className="alert" role="alert">
                                <td>{budget.valeur} min</td>
                                <td>
                                  {budget.pourcentage >= 100 ? (
                                    <div className="d-flex align-items-center">
                                      <span className="mr-2">
                                        {budget.alertes}
                                      </span>
                                      <GoAlertFill
                                        className="alertIcon"
                                        style={{ verticalAlign: "middle" }}
                                      />
                                      <span className="ml-2">
                                        dépassé le {budget.dateAlerte}
                                      </span>
                                    </div>
                                  ) : (
                                    `${budget.pourcentage}%`
                                  )}
                                </td>
                                <td>
                                  <MdOutlineDeleteForever
                                    className="deleteBudget"
                                    onClick={() =>
                                      handleConfirmDelete(budget._id)
                                    }
                                  />
                                </td>
                              </tr>
                            );
                        })}
                    </tbody>
                  </Table>
                )}
                <ModalConfirm
                  idBudget={idBudget}
                  show={confirmDelete}
                  onHide={() => {
                    setConfirmDelete(!confirmDelete);
                    dispatch(getBudgetList());
                  }}
                />
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default Budget;
