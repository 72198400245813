import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import "./style.css";
import {
  getInfoAllAgentAndCampagne,
  updateOneCampagne,
} from "../../redux/actions/compagneActions";
import { getNumeroDisponible } from "../../redux/actions/extensionActions";
import Select from "react-select";
const ModalParametreCampagne = (props) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const [nom, setNom] = useState("");
  const [description, setDescription] = useState("");
  const compte = useSelector((state) => state.compteInfo);
  const { compteInfo } = compte;
  const [numeros, setNumeros] = useState([]);
  const [formattedNumero, setFormattedNumero] = useState([]);
  const [formattedAgents, setFormattedAgents] = useState([]);
  const [selectedNumero, setSelectedNumero] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const handleSave = () => {
    const updatedNumero = selectedNumero.map((numero) => numero.value);
    const updatedAgents = selectedAgents.map((agent) => agent.value);
    const data = {
      nom: nom,
      description: description,
      numeros: updatedNumero,
      agents: updatedAgents,
    };
    updateOneCampagne(props.infoCampagne?._id, data, userInfo.token)
      .then((res) => {
        console.log(res);
        if(res.data){
          props.cell.getRow().update({...res.data,agentsAttache:res.data.agents?.length ? res.data.agents.length :0,numeorsAttache:res.data.numeros?.length ? res.data.numeros.length :0 })
          addToast("Enregistrement avec succès.", {
            appearance: "success",
            autoDismiss: true,
          });
          props.onHide()
        }
      })
      .catch((err) => {
        console.log(err);
        addToast("Une erreur s'est produite", {
          appearance: "error",
          autoDismiss: true,
        });
      });
    props.onHide();
  };
  useEffect(() => {
    setNom(props.infoCampagne?.nom ? props.infoCampagne.nom : "");
    setDescription(
      props.infoCampagne?.description ? props.infoCampagne.description : ""
    );
    if (props.infoCampagne?.agents?.length > 0) {
      const updatedOptions = props.infoCampagne.agents.map((el) => {
        return {
          value: el._id,
          label: el.nom,
        };
      });
      setSelectedAgents(updatedOptions);
    }
     if(props.infoCampagne?.numeros?.length>0){
      const updatedOptions = props.infoCampagne.numeros.map((el)=>{
        return {
            value:el._id,
            label:el.numero
        }
      })
      setSelectedNumero(updatedOptions)
     }
  }, [props.infoCampagne]);

  useEffect(() => {
    if (numeros.length > 0) {
      const updatedOptions = numeros.map((numero) => ({
        value: numero._id,
        label: numero.numero,
      }));
      setFormattedNumero(updatedOptions);
    }
    if (compteInfo && compteInfo.agentsWithDetails) {
      const updatedOptions = compteInfo.agentsWithDetails.map((numero) => ({
        value: numero._id,
        label: numero.prenom,
      }));
      setFormattedAgents(updatedOptions);
    }
  }, [numeros, compteInfo]);

  useEffect(() => {
    dispatch(getInfoAllAgentAndCampagne());
    getNumeroDisponible(userInfo.token)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setNumeros(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
 //modal parametrage numero
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-create-budget"
    >
      <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <h5>MODIFICATION CAMPAGNE</h5>
        <span onClick={props.onHide}>
          <i class="bi bi-x"></i>
        </span>
      </Modal.Header>
      <Form onSubmit={(ev) => handleSave()}>
        <Modal.Body className="modal-create-budget-body">
          <Form.Group className="mb-3">
            <Row>
              <Col sm="12">
                <Form.Label>Nom:</Form.Label>
              </Col>
              <Col sm="12">
                <div className="input-group">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Entrez le nom de campagne"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                    className="custom-form-control"
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="12">
                <Form.Label>Description:</Form.Label>
              </Col>
              <Col sm="12">
                <div className="input-group">
                  <Form.Control
                    required
                    type="text"
                    placeholder="Entrez le description pour ce campagne"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="custom-form-control"
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="12">
                <Form.Label>Agents:</Form.Label>
              </Col>
              <Col sm="12">
                <Select
                  value={selectedAgents}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  isMulti
                  options={formattedAgents ? formattedAgents : null}
                  onChange={(e) => setSelectedAgents(e)}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="12">
                <Form.Label>Numéro:</Form.Label>
              </Col>
              <Col sm="12">
                <Select
                  value={selectedNumero}
                  closeMenuOnSelect={false}
                  blurInputOnSelect={false}
                  isMulti
                  options={formattedNumero ? formattedNumero : null}
                  onChange={(e) => setSelectedNumero(e)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" class="btn btn-danger" onClick={props.onHide}>
            Fermer
          </button>
          <button
            className="btn"
            type="submit"
            style={{
              backgroundColor: "#61CE70",
              color: "white",
              marginLeft: "10px",
            }}
          >
            Enregistrer
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalParametreCampagne;
