import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { updateOneCampagne } from "../../redux/actions/compagneActions";
const ConfirmDelete = (props) => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { addToast } = useToasts();

  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal"
      >
        <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          {" "}
          <h5>Confirmation</h5>
          <span onClick={() => props.setClose(false)}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body>Voulez vous vraiment supprimer cette campagne?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.setClose(false);
            }}
          >
            Fermer
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#61CE70 ",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={(ev) => {
              const data = {
                active: false,
              };
              updateOneCampagne(props.infoCampagne?._id, data, userInfo.token)
                .then((res) => {
                  addToast("Suppression avec succès.", {
                    appearance: "success",
                    autoDismiss: true,
                  });
                  props.setBool(!props.bool);
                  props.setClose(false)
                })
                .catch((err) => {
                  console.log(err);
                  addToast("Une erreur s'est produite", {
                    appearance: "error",
                    autoDismiss: true,
                  });
                  props.setClose(false)
                });
            }}
          >
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmDelete;
