import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { login } from "../../redux/actions/userActions";
import logo from "../../assets/images/Logo_Noir-1.png";
import "./style.css";
const Login = () => {
  const { addToast } = useToasts();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, userInfo } = userLogin;

  const queryParameters = new URLSearchParams(window.location.search);

  const redirect = queryParameters ? queryParameters.get("target") : "/";

  useEffect(() => {
    if (userInfo?.token) {
      navigate(redirect ? redirect : "/");
    }
  }, [userInfo]);
  useEffect(() => {
    if (error) {
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [error]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(login(email, password));
  };
  /* useEffect(() => {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const container = document.getElementById("container");

    signUpButton.addEventListener("click", () => {
      container.classList.add("right-panel-active");
    });

    signInButton.addEventListener("click", () => {
      container.classList.remove("right-panel-active");
    });
  }, []); */
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="bodyVt">
           {/*  {error && <Message variant="danger">{error}</Message>} */}
            <div class="container containerVt" id="container">
              <div class="form-container sign-up-container">
                <form action="#" className="forms">
                  <img
                    src={logo}
                    className="logo"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <input type="text" placeholder="Nom" className="inputs" />
                  <input type="email" placeholder="Email" className="inputs" />

                  <button
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="buttonVt"
                  >
                    Demander un démo
                  </button>
                </form>
              </div>
              <div class="form-container sign-in-container">
                <form onSubmit={submitHandler} className="forms">
                  <img
                    src={logo}
                    className="logo"
                    alt=""
                    style={{ height: "50px" }}
                  />
                  <input
                    type="email"
                    className="inputs"
                    placeholder="Email"
                    id="mailInputLogin"
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    required
                  />
                  <input
                    type="password"
                    className="inputs"
                    placeholder="Mot de passe"
                    id="passInputLogin"
                    onChange={(ev) => {
                      setPassword(ev.target.value);
                    }}
                    required
                  />
                  <a className="aVt" href="#">
                    Mot de passe oublié?
                  </a>
                  <button type="submit" className="buttonVt" id="loginButton">
                    Connexion
                  </button>
                </form>
              </div>
              <div class="overlay-container">
                <div class="overlay">
                  <div class="overlay-panel overlay-left">
                    <h1 className="h1Vt">Vous avez déjà un compte !?</h1>
                    <p className="pVt">
                      Pour vous connecter, cliquez sur ce bouton et indiquez vos
                      identifiants.
                    </p>
                    <button class="buttonVt ghost" id="signIn">
                      Se connecter
                    </button>
                  </div>
                  <div class="overlay-panel overlay-right">
                    <h1 className="h1Vt">TABLEAU DE BORD</h1>
                    <p className="pVt">
                      Entrez votre identifiant et votre mot de passe pour vous
                      connecter.
                    </p>
                    <button class=" buttonVt ghost" id="signUp">
                      Appel Manuel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
