import {
  TRUNK_LIST_REQUEST,
  TRUNK_LIST_SUCCESS,
  TRUNK_LIST_FAIL,
  TRUNK_GLOBAL_INFO_REQUEST,
  TRUNK_GLOBAL_INFO_SUCCESS,
  TRUNK_GLOBAL_INFO_FAIL
} from "../constants/trunkConstants";
export const trunkReducer = (
  state = {
    trunks: [],
  },
  action
) => {
  switch (action.type) {
    case TRUNK_LIST_REQUEST:
      return { trunk_list_loading: true,trunks:[] };
    case TRUNK_LIST_SUCCESS:
      return {
        trunk_list_loading: false,
        trunks: action.payload,
      };
    case TRUNK_LIST_FAIL:
      return {
        trunk_list_loading: false,
        trunk_list_error: action.payload,
        trunks:[]
      };
    default:
      return state;
  }
};
export const trunkGlobalInfoReducer = (
  state = {
    dataGlobal: {},
  },
  action
) => {
  switch (action.type) {
    case TRUNK_GLOBAL_INFO_REQUEST:
      return { trunk_global_info_loading: true,dataGlobal:{} };
    case TRUNK_GLOBAL_INFO_SUCCESS:
      return {
        trunk_global_info_loading: false,
        dataGlobal: action.payload,
      };
    case TRUNK_GLOBAL_INFO_FAIL:
      return {
        trunk_global_info_loading: false,
        trunk_global_info_error: action.payload,
        dataGlobal:{}
      };
    default:
      return state;
  }
};
