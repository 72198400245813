import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { updateRequest } from "../../redux/actions/demandeActions";
import { useSelector } from "react-redux";
const ModalConfirmation = (props) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const updateData = useSelector((state) => state.requestUpdate);
  const { success, error } = updateData;
  useEffect(() => {
    if (success) {
      addToast("Demande annulée avec succès.", {
        appearance: "success",
        autoDismiss: true,
      });
      props.setClose(!props.close);
      props.setBool(!props.bool);
      dispatch({
        type: "DEMANDE_UPDATE_REQUEST",
      });
    }
    if (error) {
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
      props.setClose(!props.close);
      props.setBool(!props.bool);
    }
  }, [success, error]);
  return (
    <>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal"
      >
        <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          {" "}
          <h5>Confirmation</h5>
          <span onClick={() => props.setClose(false)}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body>Voulez vous vraiment annuler cette demande?</Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.setClose(false);
            }}
          >
            Fermer
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#61CE70 ",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={(ev) => {
              dispatch(updateRequest(props.idDemande, { statut: 3 }));
              props.setBool(!props.bool);
            }}
          >
            Confirmer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
