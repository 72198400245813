import React from "react";
import moment from "moment";

const FiltreHistorique = (props) => {
  return (
    <>
      <div class="offcanvas offcanvas-end" id="filtre">
        <div class="offcanvas-header">
          <h1 class="offcanvas-title">Filtre</h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
          ></button>
        </div>
        <div class="offcanvas-body">
          <div className="simpleTexte">Type d'appel</div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <button
              className="btn btn-sm"
              style={
                props.filtreAppel.includes(1)
                  ? { backgroundColor: "#6cc070 ", color: "white" }
                  : { border: "solid #6cc070 1px ", color: "#6cc070 " }
              }
              onClick={(ev) => {
                if (props.filtreAppel.includes(1)) {
                  props.setFiltreAppel(
                    props.filtreAppel.filter((el) => el != 1)
                  );
                } else {
                  props.setFiltreAppel([...props.filtreAppel, 1]);
                }
              }}
            >
              {" "}
              entrante
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreAppel.includes(2)
                  ? { backgroundColor: "#00e7eb ", color: "white" }
                  : { border: "solid #00e7eb 1px ", color: "#00e7eb " }
              }
              onClick={(ev) => {
                if (props.filtreAppel.includes(2)) {
                  props.setFiltreAppel(
                    props.filtreAppel.filter((el) => el != 2)
                  );
                } else {
                  props.setFiltreAppel([...props.filtreAppel, 2]);
                }
              }}
            >
              {" "}
              sortante
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreAppel.includes(3)
                  ? { backgroundColor: "#EB455F ", color: "white" }
                  : { border: "solid #EB455F 1px ", color: "#EB455F " }
              }
              onClick={(ev) => {
                if (props.filtreAppel.includes(3)) {
                  props.setFiltreAppel(
                    props.filtreAppel.filter((el) => el != 3)
                  );
                } else {
                  props.setFiltreAppel([...props.filtreAppel, 3]);
                }
              }}
            >
              {" "}
              Manqué
            </button>
            <button
              className="btn btn-sm"
              style={
                props.filtreAppel.includes(4)
                  ? { backgroundColor: "orange ", color: "white" }
                  : { border: "solid orange 1px ", color: "orange " }
              }
              onClick={(ev) => {
                if (props.filtreAppel.includes(4)) {
                  props.setFiltreAppel(
                    props.filtreAppel.filter((el) => el != 4)
                  );
                } else {
                  props.setFiltreAppel([...props.filtreAppel, 4]);
                }
              }}
            >
              {" "}
              Répondeur
            </button>
          </div>
          <div className="simpleTexte" style={{ marginTop: "20px" }}>
            date
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.startDate && props.startDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setStartDate(newValue);
              }}
            />
            <input
              type="date"
              className="form-control m-2"
              defaultValue={props.endDate && props.endDate.split("T")[0]}
              onChange={(ev) => {
                ev.preventDefault();
                ev.stopPropagation();
                const newValue = moment(ev.target.value).format(
                  "YYYY-MM-DDTHH:mm:ss"
                );
                props.setEndDate(newValue);
              }}
            />
          </div>
          <div className="simpleTexte" style={{ marginTop: "20px" }}>
            Badge
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <button
              className="btn btn-sm"
              style={{
                border: "solid lightgray 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(1)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 1)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 1]);
                }
              }}
            >
              <i class="bi bi-star" style={{ color: "gray" }}></i>
              {props.filtreBadge.includes(1) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070" }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #FFC436 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(2)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 2)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 2]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "#FFC436" }}></i>
              {props.filtreBadge.includes(2) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #00e7eb 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(3)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 3)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 3]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "#00e7eb" }}></i>
              {props.filtreBadge.includes(3) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid orange 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(4)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 4)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 4]);
                }
              }}
            >
              <i class="bi bi-star-fill" style={{ color: "orange" }}></i>
              {props.filtreBadge.includes(4) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #EB455F 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(5)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 5)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 5]);
                }
              }}
            >
              <i
                class="bi bi-exclamation-circle-fill"
                style={{ color: "#EB455F" }}
              ></i>
              {props.filtreBadge.includes(5) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
            <button
              className="btn btn-sm"
              style={{
                border: "solid #6cc070 1px",
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-around",
              }}
              onClick={(ev) => {
                if (props.filtreBadge.includes(6)) {
                  props.setFiltreBadge(
                    props.filtreBadge.filter((el) => el != 6)
                  );
                } else {
                  props.setFiltreBadge([...props.filtreBadge, 6]);
                }
              }}
            >
              <i
                class="bi bi-check-circle-fill"
                style={{ color: "#6cc070" }}
              ></i>
              {props.filtreBadge.includes(6) && (
                <>
                  <i
                    class="bi bi-bookmark-check-fill"
                    style={{ color: "#6cc070 " }}
                  ></i>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltreHistorique;
