import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
const ModalAlert = (props) => {
  const [listAlert, setListAlert] = useState([]);
  useEffect(() => {
    console.log(props.listAlert);
    if (props.listAlert && props.listAlert.length > 0) {
      setListAlert(props.listAlert);
    }
  }, [props.listAlert]);
  return (
    <>
      <Modal {...props}>
        <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Alertes </h5>
          <span onClick={props.onHide}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body>
          {listAlert && listAlert.length > 0 ? (
            listAlert.map((el) => {
              return <>
              <h6 style={{color:'red'}}>-{el.createdAt}:</h6>
              <p /* style={{color:'red'}} */>
                {el.information ? el.information : "---"}
              </p>
              </>;
            })
          ) : (
            <>
              <h6>-Aucune alerte pour le moment!</h6>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.onHide();
            }}
          >
            Fermer
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAlert;
