import React from "react";
import PropTypes from "prop-types";
import "./style.css";

const Card = ({ cardValeur, cardTitle }) => {
  return (
    <div className="card pl-2 mx-1 my-2 card-min-width" style={{width:'200px'}}>
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <h3 className="mb-0 card-value lowercase-text">{cardValeur}</h3>
          </div>
        </div>
        <h6 className="text-muted card-title">{cardTitle}</h6>
      </div>
    </div>
  );
};

Card.propTypes = {
  cardValeur: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
};

export default Card;
