import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import "./style.css";
import { getAllCompagne } from "../../redux/actions/compagneActions";
import CreateCampagne from "./CreateCampagne";
import ModalParametreCampagne from "./ModalParametreCampagne";
import ConfirmDelete from "./ConfirmDelete";
import { searchOptimizedData } from "../../utils/recherche";
import { ReactTabulator } from "react-tabulator";

const moment = require("moment");
const Campagne = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  //gestion session
  if (!userInfo?.token) {
    navigate("/login");
  }
  const compagneList = useSelector((state) => state.compagneList);
  const { loading, error, listCompagne } = compagneList;
  const [bool, setBool] = useState(false);
  const [dateIncorrect, setDateIncorrect] = useState(false);
  const [modalCreateCampagne, setModalCreateCampagne] = useState(false);
  const [modalParametre, setModalParametre] = useState(false);
  const [infoCampagne, setInfoCampagne] = useState();
  const [cell, setCell] = useState();
  const [dataRequest, setDataRequest] = useState();
  const [modalDelete, setModalDelete] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search);
  const redirect = queryParameters ? queryParameters.get("from") : "/";
  const fieldsToFilter = [
    "nom",
    "description",
    "agentsAttache",
    "numeroAttache",
    "createdAt",
  ];

  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //boutton parametrage
  const buttonAssignment = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-link-45deg";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setModalParametre(true);
      setInfoCampagne(data);
      setCell(cell);
    });
    const container = document.createElement("div");
    container.appendChild(btn);

    return container;
  };
  //boutton parametrage
  const buttonDelete = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn btn-danger";
    const icon = document.createElement("icon");
    icon.className = "bi bi-trash-fill";
    //icon.style='font-size:16px'
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setModalDelete(true);
      setInfoCampagne(data);
      setCell(cell);
    });
    const container = document.createElement("div");
    container.appendChild(btn);

    return container;
  };
  //Colonne react tabulator numero
  const colonneCampagne = [
    { title: "Nom", minWidth: 150, field: "nom", formatter: simpleInput },
    {
      title: "Description",
      minWidth: 150,
      field: "description",
      formatter: simpleInput,
    },
    {
      title: "Nb agents",
      minWidth: 150,
      field: "agentsAttache",
      formatter: simpleInput,
    },
    {
      title: "Nb numéros",
      minWidth: 150,
      field: "numeroAttache",
      formatter: simpleInput,
    },
    {
      title: "Crée le",
      minWidth: 150,
      field: "createdAt",
      formatter: simpleInput,
    },
    { title: "Plus", width: 100, field: "", formatter: buttonAssignment },
    { title: "Supprimer", width: 100, field: "", formatter: buttonDelete },
  ];

  useEffect(() => {
    dispatch(
      getAllCompagne(
        `${
          startDate && endDate
            ? `createdAt[gte]=${moment
                .utc(startDate)
                .startOf("day")}&createdAt[lte]=${moment
                .utc(endDate)
                .endOf("day")}`
            : ""
        }`
      )
    );
  }, [bool]);

  useEffect(() => {
    if (listCompagne && listCompagne.data) {
      const newArray = listCompagne.data.map((obj) => ({
        ...obj,
        agentsAttache: obj.agents?.length ? obj.agents.length : 0,
        numeroAttache: obj.numeros?.length ? obj.numeros.length : 0,
      }));
      setDataRequest(newArray);
    }
  }, [listCompagne]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {error && (
            <Row>
              <Message variant="danger">{error}</Message>
            </Row>
          )}

          <Row className="d-flex flex-wrap justify-content-between align-items-center ">
            <Col className="col-12">
              <h5 className="current-route text-capitalize">
                {redirect && redirect === "/" ? (
                  <Link to={"/"}>/ Tableau De Bord </Link>
                ) : redirect && redirect === "/Acces" ? (
                  <Link to={"/"}>/ Accès </Link>
                ) : (
                  <></>
                )}
                / Campagne /
              </h5>
            </Col>
          </Row>

          <Row>
            <div className="tabHistorique" style={{ width: "100%" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "0px 0px 0px 40px",
                  marginBottom: "20px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="titreTabulator">
                    Liste des campagnes -{" "}
                    {dataRequest?.length ? dataRequest.length : 0} campagne(s)
                  </span>
                </div>
                <div
                  className="champRecherche"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {modalCreateCampagne && (
                    <CreateCampagne
                      show={modalCreateCampagne}
                      onHide={() => {
                        setModalCreateCampagne(!modalCreateCampagne);
                      }}
                      setBool={setBool}
                      bool={bool}
                    />
                  )}
                  {modalParametre && (
                    <>
                      <ModalParametreCampagne
                        show={modalParametre}
                        onHide={() => {
                          setModalParametre(false);
                        }}
                        infoCampagne={infoCampagne}
                        cell={cell}
                      />
                    </>
                  )}

                  {modalDelete && (
                    <>
                      <ConfirmDelete
                        show={modalDelete}
                        setClose={setModalDelete}
                        infoCampagne={infoCampagne}
                        cell={cell}
                        bool={bool}
                        setBool={setBool}
                      />
                    </>
                  )}
                  <button
                    className="btn"
                    style={{
                      backgroundColor: "#61CE70 ",
                      color: "white",
                      marginLeft: "10px",
                    }}
                    onClick={(ev) => setModalCreateCampagne(true)}
                  >
                    Ajouter
                  </button>

                  <span
                    style={{
                      padding: "8px 12px 8px 12px",
                      backgroundColor: "rgba(226, 232, 240, 0.6)",
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                      marginLeft: "10px",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: "18px", height: "18px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="rechercheTabulator"
                      placeholder="Recherche"
                      onChange={(ev) => {
                        const dataSearch = searchOptimizedData(
                          ev.target.value,
                          dataRequest,
                          fieldsToFilter
                        );
                        setDataRequest(dataSearch);
                      }}
                    />
                  </span>
                </div>
              </div>
              <ReactTabulator
                data={dataRequest ? dataRequest : []}
                columns={colonneCampagne}
                layout={"fitColumns"}
                options={{
                  locale: true,
                  pagination: "local",
                  paginationSize: 5,
                  langs: {
                    fr: {
                      pagination: {
                        first: "Premier",
                        first_title: "Première",
                        last: "Dernier",
                        last_title: "Dernière",
                        prev: "Précédent",
                        prev_title: "Précédente",
                        next: "Suivant",
                        next_title: "Page suivante",
                        page_size: "Taille de page",
                        page_title: "Afficher la page",
                        all: "Tous",
                      },
                    },
                  },
                }}
              />

              {dateIncorrect ? (
                <Message variant="danger">
                  La date de début doit être antérieure à la date de fin.
                </Message>
              ) : (
                listCompagne &&
                listCompagne.data?.length < 1 && (
                  <Message>Aucune campagne.</Message>
                )
              )}
            </div>
          </Row>
        </Container>
      )}
    </>
  );
};
export default Campagne;
