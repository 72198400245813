import {
  APPEL_LIST_REQUEST,
  APPEL_LIST_SUCCESS,
  APPEL_LIST_FAIL,
} from "../constants/appelConstatns";
import axios from "axios";

export const getHistoriqueListForOneUser = (id, query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: APPEL_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/utilisateurs/agentsHistories/${id}?${query}`,
      config
    );
    dispatch({
      type: APPEL_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: APPEL_LIST_FAIL,
      payload: message,
    });
  }
};
