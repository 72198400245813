import {
  COMPAGNE_STATS_REQUEST,
  COMPAGNE_STATS_SUCCESS,
  COMPAGNE_STATS_FAIL,
  COMPAGNE_LIST_REQUEST,
  COMPAGNE_LIST_SUCCESS,
  COMPAGNE_LIST_FAIL,
  COMPAGNE_CREATE_REQUEST,
  COMPAGNE_CREATE_SUCCESS,
  COMPAGNE_CREATE_FAIL,
  COMPAGNE_UPDATE_REQUEST,
  COMPAGNE_UPDATE_SUCCESS,
  COMPAGNE_UPDATE_FAIL,
  COMPAGNE_ASSIGN_ACCESS_REQUEST,
  COMPAGNE_ASSIGN_ACCESS_SUCCESS,
  COMPAGNE_ASSIGN_ACCESS_FAIL,
  CAMPAGNE_ASSIGN_NUMERO_REQUEST,
  CAMPAGNE_ASSIGN_NUMERO_SUCCESS,
  CAMPAGNE_ASSIGN_NUMERO_FAIL,
  CAMPAGNE_DETAILS_REQUEST,
  CAMPAGNE_DETAILS_SUCCESS,
  CAMPAGNE_DETAILS_FAIL,
  CAMPAGNE_UPDATE_NUMERO_REQUEST,
  CAMPAGNE_UPDATE_NUMERO_SUCCESS,
  CAMPAGNE_UPDATE_NUMERO_FAIL,
} from "../constants/compagneConstants";

export const compteInfoReducer = (
  state = {
    stats: {
      alertes: 0,
      agentsCounts: 0,
      nbHeureAppel: 0,
      nbDepenses: 0,
    },
    agentsWithDetails: [],
  },
  action
) => {
  switch (action.type) {
    case COMPAGNE_STATS_REQUEST:
      return { loading: true };
    case COMPAGNE_STATS_SUCCESS:
      return { loading: false, compteInfo: action.payload };
    case COMPAGNE_STATS_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const compagneListReducer = (
  state = { stats: {}, listCompagne: [] },
  action
) => {
  switch (action.type) {
    case COMPAGNE_LIST_REQUEST:
      return { loading: true };
    case COMPAGNE_LIST_SUCCESS:
      return { loading: false, listCompagne: action.payload };
    case COMPAGNE_LIST_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
export const createCampagneReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPAGNE_CREATE_REQUEST:
      return { loading: true };
    case COMPAGNE_CREATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case COMPAGNE_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const campagneAccessUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPAGNE_UPDATE_REQUEST:
      return { loading: true };
    case COMPAGNE_UPDATE_SUCCESS:
      return { loading: false, success:true };
    case COMPAGNE_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export const campagneAssignAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPAGNE_ASSIGN_ACCESS_REQUEST:
      return { loading: true };
    case COMPAGNE_ASSIGN_ACCESS_SUCCESS:
      return { loading: false, success: true };
    case COMPAGNE_ASSIGN_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const campagneAssignNumeroReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMPAGNE_ASSIGN_NUMERO_REQUEST:
      return { loading: true };
    case CAMPAGNE_ASSIGN_NUMERO_SUCCESS:
      return { loading: false, success: true };
    case CAMPAGNE_ASSIGN_NUMERO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const campagneUpdateNumeroReducer = (state = {}, action) => {
  switch (action.type) {
    case CAMPAGNE_UPDATE_NUMERO_REQUEST:
      return { loading: true };
    case CAMPAGNE_UPDATE_NUMERO_SUCCESS:
      return { loading: false, success: true };
    case CAMPAGNE_UPDATE_NUMERO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const campagneDetailsReducer = (state = { campagneDetails: [] }, action) => {
  switch (action.type) {
    case CAMPAGNE_DETAILS_REQUEST:
      return { ...state, campagne_details_loading: true };
    case CAMPAGNE_DETAILS_SUCCESS:
      return { campagne_details_loading: false, campagneDetails: action.payload };
    case CAMPAGNE_DETAILS_FAIL:
      return {
        campagne_details_loading: false,
        campagne_details_error: action.payload,
      };
    default:
      return state;
  }
};