import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
import ModalConfirmation from "./ModalConfirmation";
import MakeRequest from "./MakeRequest";
import { getAllRequest } from "../../redux/actions/demandeActions";
import ModifyRequest from "./ModifyRequest";
import { searchOptimizedData } from "../../utils/recherche";
import { useToasts } from "react-toast-notifications";

const moment = require("moment");
const Demandes = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const allRequest = useSelector((state) => state.allRequest);
  const [bool, setBool] = useState(false);
  const [isModalConfirmation, setIsModalConfirmation] = useState(false);
  const [idDemande, setIdDemande] = useState("");
  const { request_loading, request_error, allRequests } = allRequest;
  const [makeRequest, setMakeRequest] = useState(false);
  const [modalModifyRequest, setModalModifyRequest] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [dataRequest, setDataRequest] = useState();
  const [idRequest, setIdRequest] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selected, setSelected] = useState("");
  const [selectedStatut, setSelectedStatut] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const redirect = queryParameters ? queryParameters.get("from") : "/";
  const redirects = queryParameters ? queryParameters.get("from") : "/numero";
  const fieldsToFilter = ["type", "quantite", "canaux", "createdAt", "statut"];

  if (!userInfo?.token) {
    navigate("/");
  }
  //colonne Nombre demande
  const numberColonne = (cell) => {
    const data = cell.getData();
    if (data.type === "Trunk") {
      const quantiteElement = document.createElement("input");
      quantiteElement.type = "text";
      quantiteElement.disabled = true;
      quantiteElement.style =
        "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
      quantiteElement.defaultValue = `Quantité: ${
        data.quantite ? data.quantite : "---"
      }`;
      const canauxElement = document.createElement("input");
      canauxElement.type = "text";
      canauxElement.disabled = true;
      canauxElement.style =
        "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
      canauxElement.defaultValue = `Canaux: ${
        data.canaux ? data.canaux : "---"
      }`;
      const conteneur = document.createElement("div");
      conteneur.style = "display:flex;flex-direction:column";
      conteneur.appendChild(quantiteElement);
      conteneur.appendChild(canauxElement);
      return conteneur;
    } else if (data.type === "Modification Trunk") {
      const champNameTrunk = document.createElement("input");
      champNameTrunk.type = "text";
      champNameTrunk.disabled = true;
      champNameTrunk.style =
        "width:100%;border:none;font-size:16px;font-weight:600;color:#334155;background-color:white";
      champNameTrunk.defaultValue = `-${
        data?.trunkId?.label ? data.trunkId.label : "---"
      }`;
      const conteneur = document.createElement("div");
      conteneur.style = "display:flex;height:100%;align-items:center";
      conteneur.appendChild(champNameTrunk);
      return conteneur;
    } else {
      const input = document.createElement("input");
      input.type = "text";
      input.style =
        "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
      input.defaultValue = cell.getValue();
      input.disabled = true;
      const container = document.createElement("div");
      container.style = "display:flex;align-items:center;width:100%";
      container.appendChild(input);
      return container;
    }
  };
  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //colonne statut demande
  const statutColonne = (cell) => {
    const data = cell.getData();
    if (data.statut == "En cours") {
      const btn1 = document.createElement("button");
      btn1.className = "btn btn-sm btn-danger mt-2";
      btn1.style = "margin-left:10px";
      btn1.textContent = "Annuler";
      btn1.addEventListener("click", function (e) {
        setIdDemande(data._id);
        setIsModalConfirmation(true);
      });
      const btn2 = document.createElement("button");
      btn2.className = "btn btn-sm mt-2";
      btn2.style = "margin-left:10px;background-color:#6cc070;color:white";
      btn2.textContent = "Modifier";
      btn2.addEventListener("click", function (e) {
        setModalModifyRequest(true);
        setIdRequest(data._id);
      });
      const conteneurBtn = document.createElement("div");
      conteneurBtn.appendChild(btn1);
      conteneurBtn.appendChild(btn2);
      const spanValue = document.createElement("span");
      spanValue.style = "font-size:14px;font-weight:600;color:blue";
      spanValue.innerText = `${data.statut}`;
      const conteneur = document.createElement("div");
      conteneur.style =
        "display:flex;flex-wrap:wrap;justify-content:center;align-items:center";
      conteneur.appendChild(spanValue);
      conteneur.appendChild(conteneurBtn);
      return conteneur;
    } else {
      const simpleInput = document.createElement("span");
      simpleInput.style = `color:${
        data.statut == "Validée" ? "green" : "red"
      };font-size:16px;font-weight:600`;
      simpleInput.innerText = `${data?.statut ? data.statut : "---"}`;
      const container = document.createElement("div");
      container.style =
        "width:100%;height:100%;display:flex;justify-content:center;align-items:center";
      container.appendChild(simpleInput);
      return container;
    }
  };
  //Colonne react tabulator demande
  const colonneDemande = [
    { title: "Type de demande",minWidth:150, field: "type", formatter: simpleInput },
    { title: "Nombre",minWidth:150, field: "quantite", formatter: numberColonne },
    { title: "Demande du",minWidth:150, field: "createdAt", formatter: simpleInput },
    {
      title: "Statut",
      minWidth:150,
      field: "statut",
      formatter: statutColonne,
    },
  ];

  useEffect(() => {
    if (allRequests && allRequests.data) {
      const newArray = allRequests.data.map((obj) => ({
        ...obj,
        type:
          obj.type === 1
            ? "Numéro"
            : obj.type === 2
              ? "Accés"
              : obj.type === 3
                ? "Trunk"
                : obj.type === 4
                  ? "Modification Trunk"
                  : obj.type,
        statut:
          obj.statut === 1
            ? "En cours"
            : obj.statut === 2
              ? "Validée"
              : obj.statut === 3
                ? "Annulée"
                : obj.statut === 4
                  ? "Refusée"
                  : obj.statut,
      }));
      setDataRequest(newArray);
    }
  }, [allRequests]);

  useEffect(() => {
    dispatch(
      getAllRequest(
        `${
          startDate && endDate
            ? `&createdAt[gte]=${moment
                .utc(startDate)
                .startOf("day")
                .format()}&createdAt[lte]=${moment
                .utc(endDate)
                .endOf("day")
                .format()}`
            : ""
        }${selected ? `&type=${selected}` : ""}${
          selectedStatut ? `&statut=${selectedStatut}` : ""
        }&statut[ne]=3`
      )
    );
  }, [bool]);
  //fonction pour afficher le modal de demande
  const handleToMakeRequest = () => {
    setMakeRequest(!makeRequest);
  };

  //gestion des notifications
  useEffect(() => {
    if (request_error) {
      addToast(request_error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [request_error]);
  return (
    <>
      <Container>
        <Row className="d-flex flex-wrap justify-content-between align-items-center ">
          <Col className="col-12">
            <h5 className="current-route text-capitalize">
              {redirect && redirect === "/" ? (
                <Link to={"/"}>/ Tableau De Bord </Link>
              ) : redirects && redirects === "/numero" ? (
                <Link to={"/numero"}>/ Numero </Link>
              ) : (
                <></>
              )}
              / Demandes /
            </h5>
          </Col>
        </Row>

        <Row style={{ marginTop: "20px" }}>
          {/* {request_error && (
            <Row>
              <Message variant="danger">{request_error}</Message>
            </Row>
          )} */}
          <div className="tabHistorique" style={{ width: "100%" }}>
            {request_loading ? (
              <Loader />
            ) : (
              <>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    padding: "0px 0px 0px 40px",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="titreTabulator">
                      Liste des demandes -{" "}
                      {dataRequest?.length ? dataRequest.length : "0"}{" "}
                      demande(s)
                    </span>
                  </div>

                  <div
                    className="champRecherche"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#61CE70 ",
                        color: "white",
                        marginLeft: "10px",
                      }}
                      onClick={(ev) => handleToMakeRequest()}
                    >
                      Faire une demande
                    </button>

                    <span
                      style={{
                        padding: "8px 12px 8px 12px",
                        backgroundColor: "rgba(226, 232, 240, 0.6)",
                        borderRadius: "8px",
                        height: "40px",
                        display: "flex",
                        marginLeft: "10px",
                      }}
                    >
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#818CF8"
                          style={{ width: "18px", height: "18px" }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </span>
                      <input
                        type="text"
                        className="rechercheTabulator"
                        placeholder="Recherche"
                        onChange={(ev) => {
                          const dataSearch = searchOptimizedData(
                            ev.target.value,
                            dataRequest,
                            fieldsToFilter
                          );
                          setDataRequest(dataSearch);
                        }}
                      />
                    </span>
                  </div>
                </div>
                <ReactTabulator
                  data={dataRequest ? dataRequest : []}
                  columns={colonneDemande}
                  layout={"fitColumns"}
                  // events={{ cellEdited: planningEdited }}
                  options={{
                    locale: true,
                    pagination: "local",
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: "Premier",
                          first_title: "Première",
                          last: "Dernier",
                          last_title: "Dernière",
                          prev: "Précédent",
                          prev_title: "Précédente",
                          next: "Suivant",
                          next_title: "Page suivante",
                          page_size: "Taille de page",
                          page_title: "Afficher la page",
                          all: "Tous",
                        },
                      },
                    },
                  }}
                />
              </>
            )}
            {allRequests && allRequests.data?.length < 1 && (
              <Message>Aucune demande.</Message>
            )}
            {makeRequest && (
              <MakeRequest
                idRequest={checkedItems}
                show={makeRequest}
                bool={bool}
                setBool={setBool}
                onHide={() => {
                  setMakeRequest(!makeRequest);
                }}
              />
            )}
            {modalModifyRequest && (
              <ModifyRequest
                idRequest={idRequest}
                show={modalModifyRequest}
                setModalModifyRequest={setModalModifyRequest}
                bool={bool}
                setBool={setBool}
              />
            )}
            {isModalConfirmation && (
              <ModalConfirmation
                show={isModalConfirmation}
                close={isModalConfirmation}
                setClose={setIsModalConfirmation}
                idDemande={idDemande}
                bool={bool}
                setBool={setBool}
              />
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Demandes;
