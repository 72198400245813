import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import { getNumeroDisponible } from "../../redux/actions/extensionActions";
import Modal from "react-bootstrap/Modal";
import "./style.css";
import Loader from "../../components/Loader";
import Select from "react-select";
import {
  getOneRequest,
  updateRequest,
} from "../../redux/actions/demandeActions";
const ModifyRequest = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  if (!userInfo?.token) {
    navigate("/login");
  }
  const requestDetail = useSelector((state) => state.requestDetail);
  const { request_loading, request_error, requestDetails } = requestDetail;
  const [selectType, setSelectType] = useState();
  const [selectCountry, setSelectCountry] = useState();
  const [selectAllCountry, setSelectAllCountry] = useState();
  const [numberChannel, setNumberChanel] = useState(null);
  const [quantity, setQuantity] = useState(null);
  const [selectTypeAcces, setSelectTypeAcces] = useState();
  const [TrunkName, setTrunkName] = useState("");
  const [numTrunkSelected, setNumTrunKSelected] = useState([]);
  const [numDispo, setNumDispo] = useState([]);
  const [trunkId,setTrunkId] = useState()
  const typeRequest = [
    {
      value: 1,
      type: "numero",
    },
    {
      value: 2,
      type: "acces",
    },
    {
      value: 3,
      type: "trunk",
    },
    {
      value: 4,
      type: "Modification Trunk",
    },
  ];
  const count = [
    {
      name: "France",
    },
    {
      name: "Belgique",
    },
    {
      name: "Canada",
    },
    {
      name: "Autres",
    },
  ];
  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Cote d'Ivoire (Ivory Coast)",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    'Eswatini (fmr. "Swaziland")',
    "Ethiopia",
    "Fiji",
    "Finland",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];
  const type = [
    {
      value: 1,
      type: "fixe",
    },
    {
      value: 2,
      type: "mobile",
    },
    {
      value: 3,
      type: "fixe + mobile",
    },
  ];

  //Modification type (numer:1, acces:2, trunk:3)
  const handleSelectType = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectType(selectedValue);
  };
  const handleSelectCountry = (event) => {
    setSelectCountry(event.target.value);
    setSelectAllCountry(null);
  };
  const handleSelectAllCountry = (event) => {
    setSelectAllCountry(event.target.value);
  };
  const handleSelectTypeAcces = (event) => {
    setSelectTypeAcces(event.target.value);
  };
  const handleSave = () => {
    const data = {
      canaux: numberChannel,
      pays: selectAllCountry ? selectAllCountry : selectCountry,
      quantite: quantity,
      type: selectType,
      typeNumero: selectTypeAcces,
      trunkId:trunkId,
      trunkNumeros:numTrunkSelected.map((el)=>el.value)
    };
    dispatch(updateRequest(props.idRequest, data));
    addToast("Modification avec succès.", {
      appearance: "success",
      autoDismiss: true,
    });
    props.setModalModifyRequest(false);
    props.setBool(!props.bool);
  };
  useEffect(() => {
    dispatch(getOneRequest(props.idRequest));
    getNumeroDisponible(userInfo.token)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const updatedOptions = res.data.map((numero) => ({
            value: numero._id,
            label: numero.numero,
          }));
          setNumDispo(updatedOptions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [userInfo, props.idRequest]);

  useEffect(() => {
    setNumberChanel(requestDetails?.data?.canaux);
    setSelectType(requestDetails?.data?.type);
    setSelectTypeAcces(requestDetails?.data?.typeNumero);
    setQuantity(requestDetails?.data?.quantite);
    setNumberChanel(requestDetails?.data?.canaux);
    setSelectCountry(requestDetails?.data?.pays);
    if (
      requestDetails?.data?.trunkNumeros &&
      requestDetails?.data?.trunkNumeros.length > 0
    ) {
      const updatedOptions = requestDetails?.data?.trunkNumeros.map(
        (numero) => ({
          value: numero._id,
          label: numero.numero,
        })
      );
      setNumTrunKSelected(updatedOptions);
    }
    setTrunkId(requestDetails?.data?.trunkId?._id ? requestDetails?.data?.trunkId?._id : null)
    setTrunkName(requestDetails?.data?.trunkId?.label ? requestDetails?.data?.trunkId?.label : 'Non défini')
  }, [requestDetails]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal-create-budget"
    >
      <Modal.Header className="modal-create-budget-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <h5>Modification demande</h5>
        <button
          className="btn"
          onClick={(ev) => {
            props.setModalModifyRequest(false);
          }}
        >
          <i class="bi bi-x"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="modal-create-budget-body">
        {request_loading ? (
          <Loader />
        ) : (
          <>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <Form.Label>Type:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <Form.Select
                      size="lg"
                      value={selectType}
                      onChange={handleSelectType}
                      disabled
                    >
                      {typeRequest.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.type}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              {selectType != 1 && selectType != 4 && (
                <>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="4">
                        <Form.Label>Pays:</Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Select
                          size="lg"
                          value={selectCountry}
                          onChange={handleSelectCountry}
                        >
                          {count.map((type, index) => (
                            <option key={index} value={type.name}>
                              {type.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}

              {selectType != 1 && selectType != 4 && (
                <>
                  {selectCountry === "Autres" ? (
                    <Form.Group className="mb-3">
                      <Row>
                        <Col sm="4">
                          <Form.Label>Autres:</Form.Label>
                        </Col>
                        <Col sm="8">
                          <Form.Select
                            value={selectAllCountry}
                            onChange={handleSelectAllCountry}
                          >
                            <option value="">Sélectionnez un pays</option>
                            {countries.map((country, index) => (
                              <option key={index} value={country}>
                                {country}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Form.Group>
                  ) : null}
                </>
              )}
              {selectType != 1 && selectType != 4 && (
                <>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="4">
                        <Form.Label> Type accès:</Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Select
                          size="lg"
                          value={selectTypeAcces}
                          onChange={handleSelectTypeAcces}
                        >
                          {type.map((type, index) => (
                            <option key={index} value={type.value}>
                              {type.type}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
              {selectType == 3 && selectType != 4 && (
                <>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="4">
                        <Form.Label> Nombre de canaux:</Form.Label>
                      </Col>
                      <Col sm="8">
                        <div className="input-group">
                          <Form.Control
                            required
                            type="number"
                            placeholder="Entrez le nombre de canaux"
                            value={numberChannel}
                            onChange={(e) => setNumberChanel(e.target.value)}
                            className="custom-form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez entrer une quantité valide.
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
              {selectType != 4 && (
                <>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="4">
                        <Form.Label>Quantité:</Form.Label>
                      </Col>
                      <Col sm="8">
                        <div className="input-group">
                          <Form.Control
                            required
                            type="number"
                            placeholder="Entrez votre quantité"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            className="custom-form-control"
                          />
                          <Form.Control.Feedback type="invalid">
                            Veuillez entrer une quantité valide.
                          </Form.Control.Feedback>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
              {selectType == 4 && (
                <>
                 <Form.Group className="mb-3">
                   Trunk : {TrunkName}
                 </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="12">
                        <Form.Label>
                          Liste numéro attaché et disponible:
                        </Form.Label>
                      </Col>
                      <Col sm="12">
                        <Select
                          value={numTrunkSelected}
                          closeMenuOnSelect={false}
                          blurInputOnSelect={false}
                          isMulti
                          options={numDispo ? numDispo : null}
                          onChange={(e) => setNumTrunKSelected(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          class="btn btn-danger"
          onClick={() => {
            props.setModalModifyRequest(false);
          }}
        >
          Fermer
        </button>
        <button
          type="button"
          class="btn"
          style={{ color: "white", backgroundColor: "#6cc070" }}
          onClick={() => handleSave()}
        >
          Enregistrer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModifyRequest;
