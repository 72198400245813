import {
  BUDGET_LIST_REQUEST,
  BUDGET_LIST_SUCCESS,
  BUDGET_LIST_FAIL,
  BUDGET_CREATE_REQUEST,
  BUDGET_CREATE_SUCCESS,
  BUDGET_CREATE_FAIL,
  BUDGET_CREATE_RESET,
  BUDGET_DELETE_REQUEST,
  BUDGET_DELETE_SUCCESS,
  BUDGET_DELETE_FAIL,
} from "../constants/budgetConstants";

export const budgetReducer = (
  state = {
    budgets: [],
  },
  action
) => {
  switch (action.type) {
    case BUDGET_LIST_REQUEST:
      return { budget_loading: true };
    case BUDGET_LIST_SUCCESS:
      return {
        budget_loading: false,
        budgets: action.payload,
      };
    case BUDGET_LIST_FAIL:
      return {
        budget_loading: false,
        budget_error: action.payload,
      };
    default:
      return state;
  }
};

export const createBudgetReducer = (state = {}, action) => {
  switch (action.type) {
    case BUDGET_CREATE_REQUEST:
      return { loading: true };
    case BUDGET_CREATE_SUCCESS:
      return { loading: false, success: true, userInfo: action.payload };
    case BUDGET_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case BUDGET_CREATE_RESET:
      return {};
    default:
      return state;
  }
};
export const deleteBudgetReducer = (state = {}, action) => {
  switch (action.type) {
    case BUDGET_DELETE_REQUEST:
      return { delete_budget_loading: true };
    case BUDGET_DELETE_SUCCESS:
      return { delete_budget_loading: false, success: true };
    case BUDGET_DELETE_FAIL:
      return { delete_budget_loading: false, error: action.payload };
    default:
      return state;
  }
};
