
import axios from "axios";
import {
  DEMANDE_LIST_FAIL,
  DEMANDE_LIST_REQUEST,
  DEMANDE_LIST_SUCCESS,
  DEMANDE_CREATE_FAIL,
  DEMANDE_CREATE_REQUEST,
  DEMANDE_CREATE_SUCCESS,
  DEMANDE_CANCEL_REQUEST,
  DEMANDE_CANCEL_SUCCESS,
  DEMANDE_CANCEL_FAIL,
  DEMANDE_DETAIL_REQUEST,
  DEMANDE_DETAIL_SUCCESS,
  DEMANDE_DETAIL_FAIL,
  DEMANDE_UPDATE_REQUEST,
  DEMANDE_UPDATE_SUCCESS,
  DEMANDE_UPDATE_FAIL,
} from "../constants/demandeConstants";

export const getAllRequest = (query) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEMANDE_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/?${query}`,
      config
    );
    dispatch({
      type: DEMANDE_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEMANDE_LIST_FAIL,
      payload: message,
    });
  }
};
export const createRequest = (request) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEMANDE_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/`, request, config);
    dispatch({
      type: DEMANDE_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEMANDE_CREATE_FAIL,
      payload: message,
    });
  }
};
export const cancelRequest = (idRequest) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEMANDE_CANCEL_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const data = {
      demandeAnnuler: idRequest,
    };
    
    await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/cancelMany`,data,config );
    dispatch({
      type: DEMANDE_CANCEL_SUCCESS
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEMANDE_CANCEL_FAIL,
      payload: message,
    });
  }
};
export const getOneRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEMANDE_DETAIL_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/${id}`,
      config
    );

    dispatch({
      type: DEMANDE_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEMANDE_DETAIL_FAIL,
      payload: message,
    });
  }
};
export const updateRequest = (id,request) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DEMANDE_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(`${process.env.REACT_APP_BACKEND_SERVER}/api/v1/demandes/${id}`, request, config);

    dispatch({ type: DEMANDE_UPDATE_SUCCESS });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: DEMANDE_UPDATE_FAIL,
      payload: message,
    });
  }
};