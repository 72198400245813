import {
  BUDGET_LIST_REQUEST,
  BUDGET_LIST_SUCCESS,
  BUDGET_LIST_FAIL,
  BUDGET_CREATE_REQUEST,
  BUDGET_CREATE_SUCCESS,
  BUDGET_CREATE_FAIL,
  BUDGET_DELETE_REQUEST,
  BUDGET_DELETE_SUCCESS,
  BUDGET_DELETE_FAIL,
} from "../constants/budgetConstants";
import axios from "axios";

export const getBudgetList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: BUDGET_LIST_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_SERVER}/api/v1/budgets/my`,
      config
    );
    dispatch({
      type: BUDGET_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: BUDGET_LIST_FAIL,
      payload: message,
    });
  }
};
export const createBudget = (budget) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BUDGET_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();
    
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}/api/v1/budgets/`, budget, config);
    dispatch({
      type: BUDGET_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: BUDGET_CREATE_FAIL,
      payload: message,
    });
  }
};
export const deleteBudget = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: BUDGET_DELETE_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.delete(`${process.env.REACT_APP_BACKEND_SERVER}/api/v1/budgets/${id}`,config);
    dispatch({
      type: BUDGET_DELETE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: BUDGET_DELETE_FAIL,
      payload: message,
    });
  }
};