import {
  EXTENSION_LIST_REQUEST,
  EXTENSION_LIST_SUCCESS,
  EXTENSION_LIST_FAIL,
  EXTENSION_ASSIGN_ACCESS_REQUEST,
  EXTENSION_ASSIGN_ACCESS_SUCCESS,
  EXTENSION_ASSIGN_ACCESS_FAIL,
  EXTENSION_UPDATE_REQUEST,
  EXTENSION_UPDATE_SUCCESS,
  EXTENSION_UPDATE_FAIL,
  EXTENSION_ASSIGN_CAMPAGNE_SUCCESS,
  EXTENSION_ASSIGN_CAMPAGNE_FAIL,
  EXTENSION_ASSIGN_CAMPAGNE_REQUEST,
  GET_LIST_AGENT_AND_CAMPAGNE_REQUEST,
  GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS,
  GET_LIST_AGENT_AND_CAMPAGNE_FAIL
} from "../constants/extensionConstants";

export const agentNumeroReducer = (state = { numeros: [] }, action) => {
  switch (action.type) {
    case EXTENSION_LIST_REQUEST:
      return { numero_loading: true };
    case EXTENSION_LIST_SUCCESS:
      return {
        numero_loading: false,
        numeros: action.payload,
      };
    case EXTENSION_LIST_FAIL:
      return {
        numero_loading: false,
        numero_error: action.payload,
      };
    default:
      return state;
  }
};

export const assignAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTENSION_ASSIGN_ACCESS_REQUEST:
      return { loading: true };
    case EXTENSION_ASSIGN_ACCESS_SUCCESS:
      return { loading: false, success: true };
    case EXTENSION_ASSIGN_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const assignNumeroReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTENSION_ASSIGN_CAMPAGNE_REQUEST:
      return { loading: true };
    case EXTENSION_ASSIGN_CAMPAGNE_SUCCESS:
      return { loading: false, success: true };
    case EXTENSION_ASSIGN_CAMPAGNE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const numeroAccessUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case EXTENSION_UPDATE_REQUEST:
      return { loading: true };
    case EXTENSION_UPDATE_SUCCESS:
      return { loading: false, success:true };
    case EXTENSION_UPDATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const listAgentAndCampagneReducer = (state = {data:{}},action) =>{
  switch (action.type) {
    case GET_LIST_AGENT_AND_CAMPAGNE_REQUEST:
      return { loading_list: true };
    case GET_LIST_AGENT_AND_CAMPAGNE_SUCCESS:
      return { loading_list: false, data:action.payload };
    case GET_LIST_AGENT_AND_CAMPAGNE_FAIL:
      return {
        loading_list: false,
        error: action.payload,
      };
    default:
      return state;
  }
}