import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import "./style.css";
import { createRequest } from "../../redux/actions/demandeActions";
import { getTrunkListForOneUser } from "../../redux/actions/trunkActions";
import { getNumeroDisponible } from "../../redux/actions/extensionActions";
const MakeRequest = (props) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const makeRequest = useSelector((state) => state.createRequest);
  const { success, error } = makeRequest;
  const listTrunk = useSelector((state) => state.listTrunk);
  const { trunk_list_loading, trunks, trunk_list_error } = listTrunk;
  if (!userInfo?.token) {
    navigate("/login");
  }
  const typeRequest = [
    {
      value: 1,
      type: "numero",
    },
    {
      value: 2,
      type: "acces",
    },
    {
      value: 3,
      type: "trunk",
    },
    {
      value: 4,
      type: "Modification Trunk",
    },
  ];
  const country = [
    {
      name: "France",
    },
    {
      name: "Belgique",
    },
    {
      name: "Canada",
    },
    {
      name: "France + Belgique",
    },
    {
      name: "France + Belgique + Canada",
    },
    {
      name: "Autres",
    },
  ];
  const count = [
    {
      name: "France",
    },
    {
      name: "Belgique",
    },
    {
      name: "Canada",
    },
    {
      name: "Autres",
    },
  ];
  const type = [
    {
      value: 1,
      type: "fixe",
    },
    {
      value: 2,
      type: "mobile",
    },
    {
      value: 3,
      type: "fixe + mobile",
    },
  ];
  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Cote d'Ivoire (Ivory Coast)",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    'Eswatini (fmr. "Swaziland")',
    "Ethiopia",
    "Fiji",
    "Finland",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia (formerly Macedonia)",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  const [selectType, setSelectType] = useState(typeRequest[0].value);
  const [selectCountry, setSelectCountry] = useState(country[0].name);
  const [selectAllCountry, setSelectAllCountry] = useState(country[0].name);
  const [selectTypeAcces, setSelectTypeAcces] = useState(type[0].value);
  const [quantity, setQuantity] = useState(null);
  const [numberChannel, setNumberChanel] = useState(null);
  const [numDispo, setNumDispo] = useState([]);
  const [numTrunkSelected, setNumTrunKSelected] = useState([]);
  const [trunkSelected, setTrunkSelected] = useState();
  const handleSave = () => {
    let data = {};
    let namePays = "";
    if (selectCountry === "Autres") {
      namePays = selectAllCountry;
    } else {
      namePays = selectCountry;
    }
    if (selectType === 1) {
      data = {
        type: selectType,
        quantite: quantity,
        client: userInfo?.data?.user?._id ? userInfo.data.user._id : null,
      };
    } else if (selectType === 2 || selectType === 3) {
      data = {
        type: selectType,
        pays: namePays,
        typeNumero: selectTypeAcces,
        quantite: quantity,
        canaux: numberChannel,
        client: userInfo?.data?.user?._id ? userInfo.data.user._id : null,
      };
    }else if (selectType===4){
      data = {
        type:selectType,
        trunkId:trunkSelected,
        trunkNumeros :numTrunkSelected.map((el)=>el.value)
      }
    }

    dispatch(createRequest(data));
    props.setBool(!props.bool);
  };
  const handleSelectType = (event) => {
    const selectedValue = parseInt(event.target.value);
    setSelectType(selectedValue);
  };
  const handleSelectCountry = (event) => {
    setSelectCountry(event.target.value);
  };

  const handleSelectAllCountry = (event) => {
    setSelectAllCountry(event.target.value);
  };

  const handleSelectTypeAcces = (event) => {
    setSelectTypeAcces(event.target.value);
  };

  //Recuperation trunk
  useEffect(() => {
    dispatch(getTrunkListForOneUser(""));
    getNumeroDisponible(userInfo.token)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          const updatedOptions = res.data.map((numero) => ({
            value: numero._id,
            label: numero.numero,
          }));
          setNumDispo(updatedOptions);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //Selection du premier trunk
  useEffect(() => {
    if (trunks && trunks.length > 0) {
      setTrunkSelected(trunks[0]._id);
      const updatedOptions = trunks[0].numeros?.map((numero) => ({
        value: numero._id,
        label: numero.numero,
      }));
      setNumTrunKSelected(updatedOptions);
    }
  }, [trunks,selectType]);
  //Raffraichissement trunk selectionner
  useEffect(() => {
    if (trunks && trunks.length > 0) {
      trunks.map((el) => {
        if (el._id == trunkSelected) {
          const updatedOptions = el.numeros?.map((numero) => ({
            value: numero._id,
            label: numero.numero,
          }));
          setNumTrunKSelected(updatedOptions);
        }
      });
    }
  }, [trunkSelected]);
  //gestion de notification
  useEffect(() => {
    if (error) {
      addToast(error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (success) {
      addToast("Demande envoyée avec succès.", {
        appearance: "success",
        autoDismiss: true,
      });
      dispatch({
        type: "DEMANDE_CREATE_REQUEST",
      });
      props.onHide();
    }
  }, [error, success]);
  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      className="modal"
    >
      <Modal.Header className="modal-header" style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
        <h5>DEMANDE</h5>
        <button className="btn" onClick={props.onHide}>
          <i class="bi bi-x"></i>
        </button>
      </Modal.Header>
      <Modal.Body className="modal-create-budget-body">
        <Form>
          <Form.Group className="mb-3">
            <Row>
              <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                <Form.Label>Type:</Form.Label>
              </Col>
              <Col sm="8">
                <Form.Select
                  size="lg"
                  value={selectType}
                  onChange={handleSelectType}
                >
                  {typeRequest.map((type, index) => (
                    <option key={index} value={type.value}>
                      {type.type}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          {selectType === 2 ? (
            <Form.Group className="mb-3">
              <Row>
                <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                  <Form.Label>Pays:</Form.Label>
                </Col>
                <Col sm="8">
                  <Form.Select
                    size="lg"
                    value={selectCountry}
                    onChange={handleSelectCountry}
                  >
                    {country.map((type, index) => (
                      <option key={index} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          ) : selectType === 3 ? (
            <Form.Group className="mb-3">
              <Row>
                <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                  <Form.Label>Pays:</Form.Label>
                </Col>
                <Col sm="8">
                  <Form.Select
                    size="lg"
                    value={selectCountry}
                    onChange={handleSelectCountry}
                  >
                    {count.map((type, index) => (
                      <option key={index} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          ) : null}
          {selectType === 2 || selectType === 3 ? (
            <>
              {selectCountry === "Autres" ? (
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                      <Form.Label>Autres:</Form.Label>
                    </Col>
                    <Col sm="8">
                      <Form.Select
                        value={selectAllCountry}
                        onChange={handleSelectAllCountry}
                      >
                        <option value="">Sélectionnez un pays</option>
                        {countries.map((country, index) => (
                          <option key={index} value={country}>
                            {country}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                    <Form.Label> Type accès:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <Form.Select
                      size="lg"
                      value={selectTypeAcces}
                      onChange={handleSelectTypeAcces}
                    >
                      {type.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.type}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              {selectType === 3 ? (
                <Form.Group className="mb-3">
                  <Row>
                    <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                      <Form.Label> Nombre de canaux:</Form.Label>
                    </Col>
                    <Col sm="8">
                      <div className="input-group">
                        <Form.Control
                          required
                          type="number"
                          placeholder="Entrez le nombre de canaux"
                          value={numberChannel}
                          onChange={(e) => setNumberChanel(e.target.value)}
                          className="custom-form-control"
                        />
                        <Form.Control.Feedback type="invalid">
                          Veuillez entrer une quantité valide.
                        </Form.Control.Feedback>
                      </div>
                    </Col>
                  </Row>
                </Form.Group>
              ) : null}
            </>
          ) : null}
          {selectType !== 4 && (
            <>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                    <Form.Label>Quantité:</Form.Label>
                  </Col>
                  <Col sm="8">
                    <div className="input-group">
                      <Form.Control
                        required
                        type="number"
                        placeholder="Entrez votre quantité"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                        className="custom-form-control"
                      />
                      <Form.Control.Feedback type="invalid">
                        Veuillez entrer une quantité valide.
                      </Form.Control.Feedback>
                    </div>
                  </Col>
                </Row>
              </Form.Group>
            </>
          )}
          {trunks && trunks.length > 0 ? (
            <>
              {selectType === 4 && (
                <>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="4" style={{display:'flex',alignItems:'center'}}>
                        <Form.Label>Liste Trunk:</Form.Label>
                      </Col>
                      <Col sm="8">
                        <Form.Select
                          size="lg"
                          defaultValue={trunkSelected}
                          onChange={(ev) => {
                            const value = JSON.parse(ev.target.value);
                            setTrunkSelected(value._id);
                          }}
                        >
                          {trunks.map((el) => (
                            <option key={el._id} value={JSON.stringify(el)}>
                              {el.label}
                            </option>
                          ))}
                        </Form.Select>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Col sm="12">
                        <Form.Label>
                          Liste numéro attaché et disponible:
                        </Form.Label>
                      </Col>
                      <Col sm="12">
                        <Select
                          value={numTrunkSelected}
                          closeMenuOnSelect={false}
                          blurInputOnSelect={false}
                          isMulti
                          options={numDispo ? numDispo : null}
                          onChange={(e) => setNumTrunKSelected(e)}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              {selectType == 4 && (
                <>
                  <h5 style={{ textAlign: "center", marginTop: "20px" }}>
                    Vous n'avez pas de trunk disponible pour le moment
                  </h5>
                </>
              )}{" "}
            </>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" class="btn btn-danger" onClick={props.onHide}>
          Fermer
        </button>
        <button
          type="button"
          class="btn"
          style={{ color: "white", backgroundColor: "#6cc070" }}
          onClick={() => handleSave()}
        >
          Enregistrer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default MakeRequest;
