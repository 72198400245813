import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import "./style.css";
import { getNumeroList } from "../../redux/actions/extensionActions";
import ModalParametreNumero from "./ModalParametreNumero";
import { searchOptimizedData } from "../../utils/recherche";
import { ReactTabulator } from "react-tabulator";
const moment = require("moment");

const Numero = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  if (!userInfo?.token) {
    navigate("/");
  }
  const agentNumero = useSelector((state) => state.agentNumero);
  const { numero_loading, numero_error, numeros } = agentNumero;
  const [infoNum, setInfoNum] = useState();
  const [cell, setCell] = useState();
  const [dateIncorrect, setDateIncorrect] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [modalParametre, setModalParametre] = useState(false);
  const [idNumero, setIdNumero] = useState();
  const [bool, setBool] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [dataRequest, setDataRequest] = useState();

  const queryParameters = new URLSearchParams(window.location.search);
  const redirects = queryParameters ? queryParameters.get("from") : "/Acces";
  const fieldsToFilter = [
    "numero",
    "nomCampagne",
    "createdAt",
    "campagne",
    "agent",
    "nom",
  ];
  //verification de l'agent ou campagne attacher au numero
  const verificationAssignment = (numero) => {
    if (!numero.agent && !numero.campagne && !numero.trunk) {
      return "Disponible";
    }
    if (numero.agent && !numero.campagne && !numero.trunk) {
      return `${numero.agent?.nom && numero.agent.nom} ${
        numero.agent?.prenom && numero.agent.prenom
      }`;
    }
    if (!numero.agent && numero.campagne && !numero.trunk) {
      return `${numero.campagne?.nom ? numero.campagne.nom : "Non défini"}`;
    }
    if (!numero.agent && !numero.campagne && numero.trunk) {
      return `${numero.trunk?.label ? numero.trunk.label : "Non défini"}`;
    }
    if (numero.agent && numero.campagne && numero.trunk) {
      return "ce numéro rencontre un problème";
    }
  };
  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //boutton parametrage
  const buttonAssignment = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button"); 
    btn.disabled = data.trunk ? true : false;
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-link-45deg";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setModalParametre(true);
      setInfoNum(data);
      setCell(cell);
    });
    const container = document.createElement("div");
    container.appendChild(btn);

    return container;
  };
  //Colonne react tabulator numero
  const colonneNumero = [
    { title: "Numéro", minWidth: 150, field: "numero", formatter: simpleInput },
    {
      title: "Crée le",
      minWidth: 150,
      field: "createdAt",
      formatter: simpleInput,
    },
    {
      title: "Agent / campagne / trunk",
      minWidth: 150,
      field: "assignement",
      formatter: simpleInput,
    },
    {
      title: "Assigner",
      width: 100,
      field: "",
      formatter: buttonAssignment,
    },
  ];

  useEffect(() => {
    if (numeros && numeros.data) {
      const newArray = numeros.data.map((obj) => ({
        ...obj,
        assignement: verificationAssignment(obj),
      }));

      const dataSearch = searchOptimizedData(
        textSearch,
        newArray,
        fieldsToFilter
      );
      setDataRequest(dataSearch);
    }
  }, [numeros]);
  useEffect(() => {
    dispatch(
      getNumeroList(
        `${
          startDate && endDate
            ? `createdAt[gte]=${moment
                .utc(startDate)
                .startOf("day")}&createdAt[lte]=${moment
                .utc(endDate)
                .endOf("day")}`
            : ""
        }&archive=false`
      )
    );
  }, [bool]);

  return (
    <>
      <Container>
        {numero_error && (
          <Row>
            <Message variant="danger">{numero_error}</Message>
          </Row>
        )}
        <Row className="d-flex flex-wrap justify-content-between align-items-center">
          <Col className="col-12">
            <h5 className="current-route text-capitalize">
              {redirects && redirects === "/Acces" ? (
                <Link to={"/"}>/ Acces </Link>
              ) : (
                <></>
              )}
              / Numéro /
            </h5>
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <div className="tabHistorique" style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                padding: "0px 0px 0px 40px",
                marginBottom: "20px",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="titreTabulator">
                  Liste des numéros -{" "}
                  {dataRequest?.length ? dataRequest.length : 0} numéro(s)
                </span>
              </div>
              <div
                className="champRecherche"
                style={{ display: "flex", alignItems: "center" }}
              >
                {modalParametre && (
                  <ModalParametreNumero
                    idNumero={idNumero}
                    show={modalParametre}
                    setModalParametre={setModalParametre}
                    infoNum={infoNum}
                    cell={cell}
                    onHide={() => {
                      setModalParametre(false);
                    }}
                  />
                )}

                <span
                  style={{
                    padding: "8px 12px 8px 12px",
                    backgroundColor: "rgba(226, 232, 240, 0.6)",
                    borderRadius: "8px",
                    height: "40px",
                    display: "flex",
                    marginLeft: "10px",
                  }}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#818CF8"
                      style={{ width: "18px", height: "18px" }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                      />
                    </svg>
                  </span>
                  <input
                    type="text"
                    className="rechercheTabulator"
                    placeholder="Recherche"
                    onChange={(ev) => {
                      const dataSearch = searchOptimizedData(
                        ev.target.value,
                        dataRequest,
                        fieldsToFilter
                      );
                      setDataRequest(dataSearch);
                    }}
                  />
                </span>
              </div>
            </div>
            {numero_loading ? (
              <Loader />
            ) : (
              <>
                <ReactTabulator
                  data={dataRequest ? dataRequest : []}
                  columns={colonneNumero}
                  layout={"fitColumns"}
                  options={{
                    locale: true,
                    pagination: "local",
                    paginationSize: 5,
                    langs: {
                      fr: {
                        pagination: {
                          first: "Premier",
                          first_title: "Première",
                          last: "Dernier",
                          last_title: "Dernière",
                          prev: "Précédent",
                          prev_title: "Précédente",
                          next: "Suivant",
                          next_title: "Page suivante",
                          page_size: "Taille de page",
                          page_title: "Afficher la page",
                          all: "Tous",
                        },
                      },
                    },
                  }}
                />
              </>
            )}
            {dateIncorrect ? (
              <Message variant="danger">
                La date de début doit être antérieure à la date de fin.
              </Message>
            ) : (
              numeros &&
              numeros.data?.length < 1 && <Message>Aucun numéro.</Message>
            )}
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Numero;
