import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
const ModalAssignment = (props) => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [listNum, setListNum] = useState([]);
  useEffect(() => {
    if (props.selectedNumTrunk && props.selectedNumTrunk.length > 0) {
      setListNum(props.selectedNumTrunk);
    }
  }, [props.selectedNumTrunk]);
  return (
    <>
      <Modal {...props}>
        <Modal.Header style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
          <h5>Numéros </h5>
          <span onClick={props.onHide}>
            <i class="bi bi-x"></i>
          </span>
        </Modal.Header>
        <Modal.Body> 
          <div className="row">
            <div className="col-12">
              <label htmlFor="">liste des numéros du trunks:</label>
            </div>
            <div className="col-12 mt-2">
             {
              listNum && listNum.length>0 ? listNum.map((el)=>{
                return (
                  <>
                   <h6>-{el.numero}</h6>
                  </>
                )
              }) : 
              (<> <h6 style={{color:'red'}}>-Aucun numéro attaché</h6> </>)
             }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <button
            className="btn btn-danger"
            onClick={(ev) => {
              props.onHide()
            }}
          >
            Fermer
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "#61CE70 ",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={(ev) => {
              navigate('/demandes')
            }}
          >
            Demander
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalAssignment;
