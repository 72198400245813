export const DEMANDE_LIST_REQUEST = "DEMANDE_LIST_REQUEST";
export const DEMANDE_LIST_SUCCESS = "DEMANDE_LIST_SUCCESS";
export const DEMANDE_LIST_FAIL = "DEMANDE_LIST_FAIL";

export const DEMANDE_CREATE_REQUEST = "DEMANDE_CREATE_REQUEST";
export const DEMANDE_CREATE_SUCCESS = "DEMANDE_CREATE_SUCCESS";
export const DEMANDE_CREATE_FAIL = "DEMANDE_CREATE_FAIL";

export const DEMANDE_CANCEL_REQUEST = "DEMANDE_CANCEL_REQUEST";
export const DEMANDE_CANCEL_SUCCESS = "DEMANDE_CANCEL_SUCCESS";
export const DEMANDE_CANCEL_FAIL = "DEMANDE_CANCEL_FAIL";

export const DEMANDE_DETAIL_REQUEST = "DEMANDE_DETAIL_REQUEST";
export const DEMANDE_DETAIL_SUCCESS = "DEMANDE_DETAIL_SUCCESS";
export const DEMANDE_DETAIL_FAIL = "DEMANDE_DETAIL_FAIL";

export const DEMANDE_UPDATE_REQUEST = "DEMANDE_UPDATE_REQUEST";
export const DEMANDE_UPDATE_SUCCESS = "DEMANDE_UPDATE_SUCCESS";
export const DEMANDE_UPDATE_FAIL = "DEMANDE_UPDATE_FAIL";
